import React, { useContext } from "react";
import { Carousel } from "antd";
import { TgContext } from "../../common/providers/TgProvider";
import "../CombinedBanner/CombinedBanner.scss";
import "antd/dist/antd.css";

const CombinedBanner = () => {
  const BASE = process.env.REACT_APP_BASE_URL;
  const { banners } = useContext(TgContext);
  return (
    <div className="combined-banner">
      <Carousel className="carousel-images" autoplay={true} dots={false}>
        {banners.length > 0 ? (
          banners.map((item, i) => (
            <div key={item.BannerID + "banner" + i} className="image-container">
              <img src={BASE + item.path.substring(1)} alt="carousel-img" />
              {/* <h4>Search, Compare and Book Tickets</h4> */}
            </div>
          ))
        ) : (
          <div className="image-container">
            {/* <img
              src={require("../../assets/images/banner-trus-1.jpg")}
              alt="carousel-img"
            /> */}
            <h4>Search, Compare and Book Tickets</h4>
          </div>
        )}
        {/* <div className="image-container">
          <img
            src={require("../../assets/images/banner-trus-1.jpg")}
            alt="carousel-img"
          />
          <h4>Search, Compare and Book Tickets</h4>
        </div>
        <div className="image-container">
          <img
            src={require("../../assets/images/hero_image.jpg")}
            alt="carousel-img"
          />
          <h4>Search, Compare and Book Tickets </h4>
        </div>
        <div className="image-container">
          <img
            src={require("../../assets/images/banner-trus-1.jpg")}
            alt="carousel-img"
          />
          <h4>Search, Compare and Book Tickets </h4>
        </div> */}
      </Carousel>
    </div>
  );
};
export default CombinedBanner;
