import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Divider,
  Select,
  DatePicker,
  Modal,
  message,
} from "antd";
import {
  CheckCircleOutlined,
  FacebookOutlined,
  TwitterOutlined,
  LinkedinOutlined,
  MailOutlined,
} from "@ant-design/icons";

import APIClient from "../../helpers/ApiClient";

import "./ContactUsFormStyle.scss";
import { OnlyFutureDateValidator } from "../../helpers/CustomValidators";

const ContactUsForm = () => {
  const { Option } = Select;
  const { TextArea } = Input;
  const [activeCategory, setActiveCategory] = useState([]);
  const [visible, setVisible] = useState(false);
  const [socialLinks, setSocialLinks] = useState({
    facebook: "https://www.facebook.com/",
    GoogleUrl: "https://www.google.com/",
    twitter: "https://twitter.com/",
    instagram: "https://www.instagram.com/",
    linkedin: "https://www.linkedin.com/",
  });
  const categoryChange = (key) => {
    console.log("Chanignig to", key);
    setActiveCategory(categories[key].options);
  };
  useEffect(() => {
    getbusinessData();
  }, []);
  const [form] = Form.useForm();
  const validateMessages = {
    required: "",
  };
  const handleOk = () => {
    setVisible(false);
  };
  const getbusinessData = () => {
    APIClient.get("admin/settings/1")
      .then((res) => {
        if (res.status == 200) {
          const { settings } = res.data;

          setSocialLinks((prev) => ({
            ...prev,
            facebook: settings.userSocialDetails.FacebookUrl,
            GoogleUrl: settings.userSocialDetails.GoogleUrl,
            twitter: settings.userSocialDetails.Twitter,
            instagram: settings.userSocialDetails.Instagram,
            linkedin: settings.userSocialDetails.Linkedin,
          }));
        }
      })
      .catch((e) => {
        console.log("error", e);
      });
  };
  const categories = {
    MyBooking: {
      title: "My Bookings",
      options: [
        "Adding Luggage Priority Boarding",
        " Seat Selection ON the plane ",
        "Flight date change Correcting",
        "changing passenger data",
        "Checkin and Boarding Passes",
        " Help Needed",
      ],
    },
    PaymentsAndInvoice: {
      title: "Payments & Invoice",
      options: ["Changing Buyers Details", " Items on Invoice"],
    },
    Covid: {
      title: "Covid 19",
      options: ["Ticket Refund", "Booking Change"],
    },
    Complaints: {
      title: "Complaints",
      options: ["Flight", "Other Serives", "Customer Service"],
    },
    RateUs: {
      title: "Rate Us",
      options: [
        "General Impression of website",
        "Searching for offers",
        " Making a Booking",
        "Customer Service",
      ],
    },
  };

  const layout = {
    labelCol: { span: 8, style: { textAlign: "left" } },
  };

  const submitForm = (value) => {
    value.ISDCode = "91";
    submitContactUsDetails(value);
  };

  const submitContactUsDetails = (formMapData) => {
    APIClient.post("admin/contacts", formMapData)
      .then((response) => {
        if (response.status === 200) {
          setVisible(true);
          form.resetFields();
        } else if (response.status === 404) {
          message.error("Not Found", 3);
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className="contact_wrapper">
      <Row className="from-contact-style" justify="center">
        <Col lg={14} md={20} xs={24}>
          <div className="inner_wrapper">
            <div className="contact_header">
              <h2 className="common-headings">Contact Form</h2>
            </div>
            <Form
              className="contact-boxshadow"
              {...layout}
              name="ContactUsForm"
              form={form}
              validateMessages={validateMessages}
              style={{ background: "white", padding: "16px" }}
              onFinish={submitForm}
            >
              <Divider orientation="left">How Can we Help You?</Divider>
              <Form.Item
                label="Category "
                name="CategoryName"
                rules={[{ required: true, message: "Required!" }]}
              >
                <Select
 autocomplete="newpassword"  onChange={categoryChange}>
                  {Object.keys(categories).map((key) => {
                    return <option value={key}>{categories[key].title}</option>;
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                label="Message Topic "
                name="CategoryMessage"
                rules={[{ required: true, message: "Required!" }]}
              >
                <Select
 autocomplete="newpassword" >
                  {activeCategory.map((key) => {
                    return <option value={key}>{key}</option>;
                  })}
                </Select>
              </Form.Item>
              <Divider orientation="left">Write to Us</Divider>
              <Form.Item
                label="First Name "
                name="FirstName"
                rules={[
                  { required: true, message: "Required!" },
                  {
                    pattern: new RegExp(/^[a-zA-Z]+$/),
                    message: "Invalid Name",
                  },
                ]}
              >
                <Input
 autocomplete="newpassword"  className="inputbg" />
              </Form.Item>
              <Form.Item
                label="Last Name "
                name="LastName"
                rules={[
                  { required: true, message: "Required!" },
                  {
                    pattern: new RegExp(/^[a-zA-Z]+$/),
                    message: "Invalid Name",
                  },
                ]}
              >
                <Input
 autocomplete="newpassword"  className="inputbg" />
              </Form.Item>

              <Form.Item
                label="Mobile Number"
                name="MobileNumber"
                className="phno"
                rules={[
                  {
                    required: true,
                    message: "Required!",
                  },
                  {
                    minLength: 10,
                    maxLength: 10,
                    pattern: "^[0-9]{10}$",
                    message: "Must be 10 digits",
                  },
                ]}
              >
                <Input
 autocomplete="newpassword" 
                  addonBefore="+91"
                  className="inputbg"
                  placeholder="Enter Number"
                />
              </Form.Item>

              <Form.Item
                label="Email"
                name="EmailAddress"
                rules={[
                  { required: true, message: "Required!" },
                  { type: "email", message: "Enter Valid Email!" },
                ]}
              >
                <Input
 autocomplete="newpassword"  className="inputbg" type="email" />
              </Form.Item>
              <Form.Item
                label="Order Number"
                name="OrderNumber"
                rules={[{ required: true, message: "Required!" }]}
              >
                <Input
 autocomplete="newpassword"  className="inputbg" />
              </Form.Item>
              <Form.Item
                label="Departure Date"
                name="DepartureDate"
                rules={[
                  { required: true, message: "Required!" },
                  { validator: OnlyFutureDateValidator },
                ]}
              >
                <DatePicker className="dateStyle inputbg" />
              </Form.Item>

              <Form.Item
                label="Your Message"
                name="YourMessage"
                rules={[{ required: true, message: "Required!" }]}
              >
                <TextArea className="inputbg" rows={4} />
              </Form.Item>

              <div className="btn_wrapper">
                <Button
                  className="btn-hover-value-sub"
                  type="primary"
                  htmlType="submit"
                >
                  Submit
                </Button>
              </div>
            </Form>
          </div>
        </Col>
        <Modal
          visible={visible}
          title="Thank you for getting in touch!"
          onOk={handleOk}
          onCancel={handleOk}
          footer={[null]}
          className="successModalBox"
        >
          <Row justify="center">
            <Col>
              <div className="sucessModal">
                <CheckCircleOutlined className="modalIcon" />
                <h6>Your message has been successfully sent</h6>
                <p>
                  We appreciate you submitting a contact form with us. Once we
                  receive your
                </p>
                <p>
                  information we'll respond as soon as we can. In the meantime,
                  sit back, relax
                </p>
                <p>and enjoy the view</p>
              </div>
            </Col>
            <Col>
              <div className="socialLinks">
                <span className="sharetext">Share at</span>
                <span className="socialwrap">
                  <a href={socialLinks.facebook} target="_blank">
                    <FacebookOutlined />
                  </a>
                </span>
                <span className="socialwrap">
                  <a href={socialLinks.twitter} target="_blank">
                    <TwitterOutlined />
                  </a>
                </span>
                <span className="socialwrap">
                  <a href={socialLinks.linkedin} target="_blank">
                    <LinkedinOutlined />
                  </a>
                </span>
                <span className="socialwrap">
                  <a href={socialLinks.GoogleUrl} target="_blank">
                    <MailOutlined />
                  </a>
                </span>
              </div>
            </Col>
          </Row>
        </Modal>
      </Row>
    </div>
  );
};

export default ContactUsForm;
