import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Checkbox,
  Form,
  message,
  Modal,
  Popconfirm,
  Input,
  Layout,
  Row,
  Select,
  Table,
} from "antd";
import "./AccessPermissions.scss";
import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";

import moment from "moment";
import {
  PlusCircleFilled,
  EditOutlined,
  DeleteOutlined,
  CloseOutlined,
} from "@ant-design/icons";

import { exportExcel } from "../ExportExcel";
import ApiClient from "../../helpers/ApiClient";
const { Content } = Layout;
const { Option } = Select;
const AccessPermissions = () => {
  const [form] = Form.useForm();
  let key = 0;
  const [modalVisible, setModalVisible] = useState(false);
  const [screenList, setScreenList] = useState([]);
  const [screenId, setScreenId] = useState([]);
  const [rolesList, setRolesList] = useState([]);

  const [dataSource, setDataSource] = useState([]);
  const [tableList, setTableList] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [accessID, setAccessID] = useState(-1);
  const [showSearchBox, setShowSearchBox] = useState(false);

  const getRolesList = async () => {
    await ApiClient.get("admin/role")
      .then((res) => {
        if (res.status == 200) {
          setRolesList(res.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const getAccessList = () => {
    ApiClient.get("admin/accessPermissions")
      .then((res) => {
        if (res.status == 200) {
          console.log(res);
          let data = res.data.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
              CreatedDate: moment(item.CreatedDate).format("DD-MM-YYYY"),
              ModifiedDate: moment(item.ModifiedDate).format("DD-MM-YYYY"),
            };
          });
          setDataSource(data);
          setTableList(data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const getScreens = () => {
    ApiClient.get("admin/screen")
      .then((res) => {
        if (res.status == 200) {
          let screenId = res.data.map((item) => item.ID);
          setScreenId(screenId);
          let filterbyCat = res.data.reduce((obj, v, i) => {
            obj[v.CategoryName] = obj[v.CategoryName] || [];
            obj[v.CategoryName].push(v);
            return obj;
          }, {});
          setScreenList(filterbyCat);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getRolesList();
    getScreens();
    getAccessList();
  }, []);

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = tableList.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setTableList(searchResults);
    } else {
      setTableList(dataSource);
    }
  };
  const closeSearch = () => {
    setShowSearchBox(false);
    setTableList(dataSource);
  };

  const closeSearchInput = <CloseOutlined onClick={closeSearch} />;

  const showModal = () => {
    if (isEditMode) {
      setIsEditMode(false);
      form.resetFields();
    }
    setModalVisible({
      visible: true,
    });
  };

  const tableActions = (rec) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined
            onClick={() => updateRoleAccess(rec)}
            style={{ color: "#1890ff", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <Popconfirm
            onConfirm={() => deleteRoleAccess(rec.ID)}
            title="Are you sure to delete?"
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </Popconfirm>
        </div>
      </div>
    );
  };
  const getRoleName = (id) => {
    let roleName = id;
    rolesList.forEach((role) => {
      if (role.RoleID == id) {
        roleName = role.Name;
      }
    });
    return roleName;
  };

  const columnsserviceoper = [
    {
      title: "Sr. No.",
      dataIndex: "SNo",
      key: "SNo",
      sorter: (a, b) => a.SNo - b.SNo,
    },
    {
      title: "Role",
      dataIndex: "RoleID",
      render: (id) => getRoleName(id),
      sorter: (a, b) => a.RoleID - b.RoleID,
    },

    {
      title: "Created Date",
      dataIndex: "CreatedDate",
      sorter: (a, b) => a.CreatedDate.localeCompare(b.CreatedDate),
    },

    {
      title: "Modified Date",
      dataIndex: "ModifiedDate",
      sorter: (a, b) => a.ModifiedDate.localeCompare(b.ModifiedDate),
    },

    {
      title: "Actions",
      render: (_, rec) => tableActions(rec),
    },
  ];

  const submitData = (val) => {
    console.log("formval", val);
    let data = {
      RoleID: val.RoleID,
      ScreenArray: JSON.stringify(val.checkboxGroup),
      CreatedBy: 0,
      Status: 0,
      ModifiedBy: 1,
    };

    isEditMode
      ? submitUpdateAccessPermissions(data)
      : submitAccessPermissions(data);
  };

  const submitAccessPermissions = (data) => {
    ApiClient.post("admin/accessPermissions", data)
      .then((response) => {
        console.log(response);
        if (response.status == 200) {
          message.success("Role Permission Added SuccessFully", 3);
          getAccessList();
          form.resetFields();
          setModalVisible(false);
        } else if (response.status == 404) {
          message.error("Not Found", 3);
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const submitUpdateAccessPermissions = (data) => {
    ApiClient.put(`admin/accessPermissions/${accessID}`, {}, data)
      .then((response) => {
        console.log(response);
        if (response.status == 200) {
          message.success("Role Permission Updated SuccessFully", 3);
          setModalVisible(false);
          getAccessList();
          form.resetFields();
        } else if (response.status == 404) {
          message.error("Not Found", 3);
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const updateRoleAccess = (rec) => {
    form.resetFields();
    console.log("update", rec);
    setIsEditMode(true);
    setAccessID(rec.ID);
    form.setFieldsValue({
      RoleID: rec.RoleID,
      checkboxGroup: JSON.parse(rec.ScreenArray),
    });
    setModalVisible(true);
  };

  function deleteRoleAccess(id) {
    ApiClient.delete("admin/accessPermissions/" + id)
      .then((res) => {
        if (res.status == 200) {
          message.success("Role Deleted  successfully", 3);
          getAccessList();
        }
      })
      .catch((e) => {
        console.log("delete api error", e);
      });
  }

  const onCheckAllChange = (e) => {
    e.target.checked
      ? form.setFieldsValue({
          checkboxGroup: [...screenId],
        })
      : form.setFieldsValue({
          checkboxGroup: [],
        });
  };
  const handleExcel = () => {
    let excelData = tableList;
    excelData = excelData.map((item, i) => ({
      SNo: item.SNo,
      Name: getRoleName(item.RoleID),
      CreatedDate: item.CreatedDate,
      ModifiedDate: item.ModifiedDate,
    }));

    exportExcel(excelData, "Access_Permissions");
  };
  const keyGenerate = () => {
    return key++;
  };
  return (
    <Layout>
      <div className="manage-markup-section">
        <Content className="admin-container">
          <div className="card-bt-gap">
            <Card bordered={false}>
              <div className="card-add-heading">
                <div className="rows-count">
                  <h5>View Access Permissions</h5>
                  <p>{tableList.length} rows found !</p>
                </div>
                <div className="action-images">
                  {showSearchBox && (
                    <Input
 autocomplete="newpassword" 
                      placeholder="Search"
                      onChange={(e) => searchTableData(e)}
                      suffix={closeSearchInput}
                      style={{ padding: "0px 12px" }}
                    />
                  )}
                  &nbsp;&nbsp;
                  <img
                    src={search}
                    alt="search"
                    onClick={() => setShowSearchBox(true)}
                  />
                  <img src={excel} alt="excel" onClick={() => handleExcel()} />
                  <p className="add-deposit-icon" onClick={showModal}>
                    <PlusCircleFilled />
                  </p>
                </div>
              </div>

              <Table
                scroll={{ x: true }}
                bordered
                dataSource={tableList}
                columns={columnsserviceoper}
                rowKey={keyGenerate}
                pagination={{
                  defaultPageSize: 25,
                  showSizeChanger: true,
                  pageSizeOptions: ["25", "50", "100", "125"],
                }}
              />
            </Card>
          </div>
        </Content>
      </div>
      <Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>Add Role</strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header permissions-modal"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={[
          <>
            <Button key="add" type="primary" onClick={form.submit}>
              {isEditMode ? "Update" : "Add"}
            </Button>
            <Button key="submit" type="danger">
              Clear
            </Button>
          </>,
        ]}
      >
        <Form layout="vertical" form={form} onFinish={submitData}>
          <Row>
            <Col md={24} xs={24}>
              <Form.Item
                label="Role"
                name="RoleID"
                rules={[
                  {
                    required: true,
                    message: "Please input your Role",
                  },
                ]}
              >
                {/* <Input
 autocomplete="newpassword"  placeholder="Enter Role" size={"large"} /> */}
                <Select
 autocomplete="newpassword"  placeholder="Select Role">
                  {rolesList.map((item, i) => (
                    <Option key={i} value={item.RoleID}>
                      {item.Name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col md={24} xs={24}>
              <Form.Item name=" Pages">
                <div className="checkbox-flex">
                  <Checkbox onChange={onCheckAllChange}>Select All</Checkbox>
                </div>
              </Form.Item>
            </Col>
          </Row>

          {Object.keys(screenList).length > 0 && (
            <>
              <Form.Item
                name="checkboxGroup"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Checkbox.Group>
                  {Object.keys(screenList).map((item) => (
                    <div key={item}>
                      <h5>{item}</h5>

                      <Row gutter={[0, 16]}>
                        {screenList[item].map((cat) => (
                          <Col key={cat.ScreenName} md={6} xs={24}>
                            <Checkbox value={cat.ID}>{cat.ScreenName}</Checkbox>
                          </Col>
                        ))}
                      </Row>
                    </div>
                  ))}
                </Checkbox.Group>
              </Form.Item>
            </>
          )}
        </Form>
      </Modal>
    </Layout>
  );
};

export default AccessPermissions;
