import { Card, Col, Row } from "antd";
import React from "react";
import { MinutesToHours } from "./../../../helpers/MinutesToHours";
import "./BusDetails.scss";
import moment from "moment";

const BusDetails = ({ selectedBusData }) => {
  // console.log("busdetails", selectedBusData);
  return (
    <div className="busDetails_wrapper">
      {selectedBusData.map((busDetail, i) => (
        <div key={"businfo" + i} className="card_wrapper">
          <Card className="border-check">
            <Row gutter={[16, 16]}>
              <Col md={6} sm={6} xs={24}>
                <p className="busname">
                  {busDetail.displayName ? busDetail.displayName : ""}
                </p>
              </Col>
              <Col md={12} sm={12} xs={24}>
                <div className="fromto_wrapper">
                  <p className="fromto">
                    {selectedBusData[i].origin ? selectedBusData[i].origin : ""}
                  </p>

                  <i className="fa fa-arrow-right"></i>

                  <p className="fromto">
                    {selectedBusData[i].destination
                      ? selectedBusData[i].destination
                      : ""}
                  </p>
                </div>
              </Col>
              <Col md={6} sm={6} xs={24}>
                <div className="bus-timings">
                  <ul>
                    <li>
                      {busDetail.boardingTimes &&
                        moment(busDetail.boardingTimes.time).format("HH:MM")}
                    </li>
                    <li className="du-fast"></li>
                    <li>
                      {busDetail.droppingTimes &&
                        moment(busDetail.droppingTimes.time).format("HH:MM")}
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
            <div className="hr"></div>
            <Row gutter={[16, 16]}>
              <Col md={12} sm={12} xs={24}>
                <div className="points_wrapper justify-content-between">
                  <div className="boarding_wrapper flex-fill">
                    {/* {console.log(busDetail.boardingTimes, busDetail.droppingTimes, busDetail, "boarding")} */}
                    <p className="point">Boarding point</p>
                    <p className="loc">
                      {busDetail.boardingTimes
                        ? `${busDetail.boardingTimes.name} (${busDetail.boardingTimes.address ?? ""})`
                        // ? `${busDetail.boardingTimes.name} (${busDetail.boardingTimes.address})` 
                        : ""}
                    </p>
                  </div>

                  <i className="fa fa-arrow-right px-2"></i>

                  <div className="dropping_wrapper flex-fill">
                    <p className="point">Dropping point</p>
                    <p className="loc">
                      {busDetail.droppingTimes
                        ? `${busDetail.droppingTimes.name} (${busDetail.droppingTimes.address ?? ""})`
                        // ? `${busDetail.droppingTimes.name} (${busDetail.droppingTimes.address})`
                        : ""}
                    </p>
                  </div>
                </div>
              </Col>
              <Col md={8} sm={8} xs={24}>
                <p className="busType">
                  {busDetail.busType ? busDetail.busType : ""}
                </p>
                {busDetail?.boardingTimes?.phone &&
                  <div className="opdetail">
                    <p className="optitle">Operator-Contact : {" "}
                      <span className="text" style={{color:"black"}}> 
                        {busDetail.boardingTimes
                          ? busDetail?.boardingTimes?.phone?.split(',')?.[0]
                          : "-"}
                      </span>
                    </p>
                  </div>
                }
              </Col>
              <Col md={4} sm={4} xs={24}>
                <p className="pricetitle">
                  {i === 0 ? "Onward" : "Return"} Trip Fare
                </p>
                <p className="price">{"₹ " + busDetail.fareInfo.totalFare}</p>
                <p className="infotext">Including GST</p>
              </Col>
            </Row>
          </Card>
        </div>
      ))}
    </div>
  );
};
export default BusDetails;
