import React from "react";
import "./BusDetails.scss";
import { Button, Row, Card, Checkbox, Col, Form, Input, Select } from "antd";
import GlobalStatesContext from "./../../../common/providers/GlobalStatesContext";
import { MinutesToHours } from "./../../../helpers/MinutesToHours";
import moment from "moment";
const BusDetails = () => {
  const travelType = localStorage.getItem("travelType");
  return (
    <GlobalStatesContext.Consumer>
      {(gsContext) => {
        let busData = gsContext.state.busData;
        let combineSearchData = gsContext.state.combineSearchData;
        let selectedBusData = gsContext.state.selectedBusData
          ? gsContext.state.selectedBusData
          : null;
       
        return (
          <div className="busDetails_wrapper">
            {selectedBusData.map((busDetail, i) => (
              <div key={"businfo" + i} className="card_wrapper">
                <Card>
                  <Row gutter={[16, 16]} className="gap-removed">
                    <Col md={24} sm={24} xs={24}><p className="journeyDate"> {i == 0 ? <span className="onward">JourneyDate:{moment(combineSearchData.fromDate).format('DD-MM-YYYY')}</span> : <span className="return">ReturnDate:{moment(combineSearchData.toDate).format('DD-MM-YYYY')}</span>}</p></Col>
                    <Col md={6} sm={6} xs={24}>
                      <p className="busname">
                        {busDetail.displayName ? busDetail.displayName : ""}
                      </p>
                    </Col>
                    <Col md={12} sm={12} xs={24}>
                      <div className="fromto_wrapper">
                        <p className="fromto">
                          {selectedBusData[i].origin
                            ? selectedBusData[i].origin
                            : ""}
                        </p>

                        <i className="fa fa-arrow-right"></i>

                        <p className="fromto">
                          {selectedBusData[i].destination
                            ? selectedBusData[i].destination
                            : ""}
                        </p>
                      </div>
                    </Col>
                    <Col md={6} sm={6} xs={24}>

                      <div className="bus-timings">

                        <ul>
                          <li>
                            {busDetail.boardingTimes &&
                              MinutesToHours(busDetail.boardingTimes.Time)}
                          </li>
                          <li className="du-fast"></li>
                          <li>
                            {busDetail.droppingTimes &&
                              MinutesToHours(busDetail.droppingTimes.Time)}
                          </li>
                        </ul>
                      </div>
                    </Col>
                  </Row>
                  <div className="hr"></div>
                  <Row gutter={[16, 16]}>
                    <Col md={10} sm={10} xs={24}>
                      <div className="points_wrapper">
                        <div className="boarding_wrapper">
                          <p className="point">Boarding point</p>
                          <p className="loc">
                            {busDetail.boardingTimes
                              ? busDetail.boardingTimes.Location
                              : ""}
                          </p>
                        </div>

                        <i className="fa fa-arrow-right"></i>

                        <div className="dropping_wrapper">
                          <p className="point">Dropping point</p>
                          <p className="loc">
                            {busDetail.droppingTimes
                              ? busDetail.droppingTimes.Location
                              : ""}
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col md={9} sm={9} xs={24}>
                      <p className="busType">
                        {busDetail.busType ? busDetail.busType : ""}
                      </p>
                      <div className="opdetail">
                        <p className="optitle">Operator-Contact </p>
                        <p className="text">
                          {busDetail.boardingTimes
                            ? busDetail.boardingTimes.ContactNumbers
                            : "-"}
                        </p>
                      </div>
                    </Col>
                    <Col md={5} sm={5} xs={24}>
                      <p className="pricetitle">
                        {i === 0 ? "Onward" : "Return"} Trip Fare
                      </p>
                      <p className="price">
                        {"₹ " + busDetail.fareInfo.totalFare}
                      </p>
                      <p className="infotext">{parseFloat(busDetail.fareInfo.gst) > 0 ? `Including GST` : ``}</p>
                    </Col>
                  </Row>
                </Card>
              </div>
            ))}
          </div>
        );
      }}
    </GlobalStatesContext.Consumer>
  );
};
export default BusDetails;
