import React, { useState, useEffect } from "react";
import * as ANTD from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  CloseOutlined,
  PlusCircleFilled,
} from "@ant-design/icons";
import { Modal } from "antd";
import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import exchange from "../assets/vector-icons/exchange.png";
import { exportExcel } from "../ExportExcel";
import APIClient from "../../helpers/ApiClient";
import "../../admin/Adminstyles.scss";

const BusesCommissions = () => {
  const { Option } = ANTD.Select;
  const { Content } = ANTD.Layout;
  const [modalVisible, setModalVisible] = useState(false);
  const [formLayout, setFormLayout] = useState("vertical");
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [membershipList, setMembershipList] = useState([]);
  const [busComm, setBusComm] = useState([]);
  const [operatorList, setOperatorList] = useState([]);
  const [filterOperatorList, setFilterOperatorList] = useState([]);
  const [id, setId] = useState(-1);
  const [form] = ANTD.Form.useForm();
  const validateMessages = {
    required: "",
  };

  useEffect(() => {
    getBusCommissionsList();
    getMembership();
    getOperators();
  }, []);

  const getBusCommissionsList = () => {
    APIClient.get("admin/busCommissions")
      .then((res) => {
        if (res.status == 200) {
          mapBusCommData(res.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getMembership = () => {
    APIClient.get("admin/role/default")
      .then((res) => {
        if (res.status == 200) {
          console.log("mem", res);

          setMembershipList(res.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  function mapBusCommData(busCommData) {
    // console.log("busCommData", busCommData);
    let data = busCommData.map((item, index) => {
      return {
        ...item,
        Sno: index + 1,
        BusCommissionID: item.BusCommissionID,
        RoleTypeName: getRoleType(item.RoleType),
        BusTypeName: getBusType(item.BusType),
        OperatorName: item.OperatorID.OperatorName,
        OperatorId: item.OperatorID.OperatorServiceID,
        MarkupType: item.MarkupType,
        Markup: item.Markup,
      };
    });
    setBusComm(data);
  }

  const getOperators = () => {
    APIClient.get("admin/operators")
      .then((res) => {
        if (res.status == 200) {
          console.log("list", res.data);
          let filterOperators = res.data.filter(
            (item) => item.ServiceType === 3
          );
          setOperatorList(filterOperators);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleOperator = (value) => {
    let filterOperator = operatorList.filter((item) => item.BusType == value);
    setFilterOperatorList(filterOperator);
  };

  function deleteMap(BusCommissionID) {
    APIClient.delete("admin/busCommissions/" + BusCommissionID)
      .then((res) => {
        if (res.status == 200) {
          ANTD.message.success("Deleted  successfully", 3);
          getBusCommissionsList();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const updateMap = (currentData) => {
    setIsEditMode(true);
    delete currentData.OperatorID;
    console.log("editData", currentData);

    setId(currentData.BusCommissionID);

    handleOperator(currentData.BusType);

    setModalVisible(true);
    form.setFieldsValue({
      ...currentData,
      OperatorID: currentData.OperatorId,
    });
  };

  const getRoleType = (num) => {
    if (num == 2) {
      return "Users";
    } else {
      return "Guest";
    }
  };

  const getBusType = (num) => {
    if (num == 1) {
      return "RTC Buses";
    } else if (num == 2) {
      return " Private Travels";
    }
  };

  const formItemLayout =
    formLayout === "vertical"
      ? {
          labelCol: { span: 24 },
          wrapperCol: { span: 23 },
        }
      : null;

  const showModal = () => {
    if (isEditMode) {
      setIsEditMode(false);
      form.resetFields();
    }
    setModalVisible({
      visible: true,
    });
  };

  const tableActions = (currentItem) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined
            onClick={() => updateMap(currentItem)}
            style={{ color: "#1890ff", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <ANTD.Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => deleteMap(currentItem.BusCommissionID)}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </ANTD.Popconfirm>
        </div>
      </div>
    );
  };

  const clear = () => {
    setModalVisible(false);
  };

  const submitForm = (value) => {
    console.log("formValev", value);

    const formMapData = {
      AgentID: 12,
      MembershipID: 12,
      AgentID: 12,
      CommissionType: 1,
      Commission: 1,
      CreatedBy: "1",
      DeviceOS: 1,
      ApplicationType: 1,
      DeviceOSVersion: "string",
      DeviceToken: "string",
      FirBaseToken: "string",
      ...value,
    };
    isEditMode
      ? submitUpdateBusComm(formMapData)
      : submitBusCommDetails(formMapData);
  };

  const submitBusCommDetails = (formMapData) => {
    APIClient.post("admin/busCommissions", formMapData)
      .then((response) => {
        console.log(response);
        if (response.status == 200) {
          ANTD.message.success("Details Added SuccessFully", 3);
          setModalVisible(false);
          setBusComm([]);
          getBusCommissionsList();
          form.resetFields();
        } else if (response.status == 404) {
          ANTD.message.error("Not Found", 3);
        } else if (response.status === 409) {
          ANTD.message.error(response.message, 3);
        } else {
          ANTD.message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const submitUpdateBusComm = (formMapData) => {
    APIClient.put(`admin/busCommissions/${id}`, {}, formMapData)
      .then((response) => {
        if (response.status == 200) {
          ANTD.message.success("Details Updated SuccessFully", 3);
          setModalVisible(false);
          setBusComm([]);
          getBusCommissionsList();
          form.resetFields();
        } else if (response.status == 404) {
          ANTD.message.error("Not Found", 3);
        } else if (response.status === 409) {
          ANTD.message.error(response.message, 3);
        } else {
          ANTD.message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const searchData = () => {
    setShowSearchBox(true);
  };
  const closeSearch = () => {
    setShowSearchBox(false);
  };

  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = busComm.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setBusComm(searchResults);
    } else {
      getBusCommissionsList();
    }
  };

  const getSign = (num) => {
    if (num === 1) {
      return "%";
    }
    return "";
  };

  const columns = [
    {
      title: "S. No.",
      dataIndex: "Sno",
      sorter: (a, b) => a.Sno - b.Sno,
    },

    {
      title: "Role Type",
      dataIndex: "RoleTypeName",
    },
    {
      title: "Bus Type",
      dataIndex: "BusTypeName",
      sorter: (a, b) => a.BusTypeName.localeCompare(b.BusTypeName),
    },
    {
      title: "Bus Operator",
      dataIndex: "OperatorName",
      sorter: (a, b) => a.OperatorName.localeCompare(b.OperatorName),
    },
    {
      title: "Markup",
      dataIndex: "Markup",
      render: (value, currentData) => (
        <>{value + " " + getSign(currentData.MarkupType)}</>
      ),
    },
    {
      title: "Actions",
      render: (data, currentItem) => tableActions(currentItem),
    },
  ];

  const handleExcel = () => {
    const excelData = busComm.map((item) => {
      return {
        S_No: item.Sno,
        Bus_Type: item.BusTypeName,
        Roles: item.RoleTypeName,
        Bus_Operator: item.OperatorName,
        Markup: item.Markup,
        Cash_Back_Per_Pax_Fixed: item.Pax,
        Cancellation_Charge: item.CancellationServiceCharge,
        GST_VAT_Amount: item.CancellationVATAmount,
      };
    });
    exportExcel(excelData, "BusCommissionDetails");
  };
  let key = 0;
  const keyGenerate = () => {
    return key++;
  };

  return (
    <ANTD.Layout>
      <div className="manage-markup-section">
        <Content className="container-fluid">
          <ANTD.Row>
            <ANTD.Col span={24}>
              <div className="card-bt-gap">
                <ANTD.Card bordered={false}>
                  <div className="card-add-heading">
                    <div className="rows-count">
                      <h5>View Bus Markup</h5>
                      <p>{busComm.length} rows found !</p>
                    </div>
                    <div className="action-images">
                      {showSearchBox && (
                        <ANTD.Input 
 autocomplete="newpassword" 
                          placeholder="Search"
                          onChange={(e) => searchTableData(e)}
                          suffix={closeSearchInput}
                          style={{ padding: "0px 12px" }}
                        />
                      )}
                      &nbsp;&nbsp;
                      <img
                        src={search}
                        alt="search"
                        onClick={(e) => searchData()}
                      />
                      <img src={excel} alt="excel" onClick={handleExcel} />
                      <img
                        src={exchange}
                        alt="exchange"
                        onClick={() => getBusCommissionsList()}
                      />
                      <p className="add-deposit-icon" onClick={showModal}>
                        <PlusCircleFilled />
                      </p>
                    </div>
                  </div>

                  <ANTD.Table
                    scroll={{ x: true }}
                    bordered
                    dataSource={busComm}
                    columns={columns}
                    pagination={{
                      defaultPageSize: 25,
                      showSizeChanger: true,
                      pageSizeOptions: ["25", "50", "100", "125"],
                    }}
                    rowKey={keyGenerate}
                  />
                </ANTD.Card>
              </div>
            </ANTD.Col>
          </ANTD.Row>
        </Content>
      </div>
      <Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong> {isEditMode ? "Update" : "Add"} Bus Markup</strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        width={"800px"}
        footer={[
          <div key="btnTypes">
            <ANTD.Button
              key="add"
              type="primary"
              htmlType="submit"
              onClick={() => form.submit()}
            >
              {isEditMode ? "Update" : "Add"}
            </ANTD.Button>
            <ANTD.Button key="clear" type="danger" onClick={clear}>
              Cancel
            </ANTD.Button>
          </div>,
        ]}
      >
        <ANTD.Form
          layout="vertical"
          form={form}
          onFinish={submitForm}
          validateMessages={validateMessages}
        >
          <ANTD.Row gutter={16}>
            <ANTD.Col className="gutter-row" md={8} xs={24}>
              <ANTD.Form.Item
                label="Membership Type"
                name="RoleType"
                rules={[{ required: true }]}
              >
                <ANTD.Select placeholder="Please Select">
                  {membershipList.map((item, index) => (
                    <Option key={index + "mem"} value={item.RoleID}>
                      {item.Name}
                    </Option>
                  ))}
                </ANTD.Select>
              </ANTD.Form.Item>
            </ANTD.Col>

            <ANTD.Col className="gutter-row" md={8} xs={24}>
              <ANTD.Form.Item
                label="Bus Type"
                name="BusType"
                rules={[{ required: true }]}
              >
                <ANTD.Select
                  placeholder="Please Select"
                  onChange={handleOperator}
                >
                  <Option value={1}>RTC Buses</Option>
                  <Option value={2}>Private Travels</Option>
                </ANTD.Select>
              </ANTD.Form.Item>
            </ANTD.Col>
            <ANTD.Col className="gutter-row" md={8} xs={24}>
              <ANTD.Form.Item
                label="Bus Operator"
                name="OperatorID"
                rules={[{ required: true }]}
              >
                <ANTD.Select placeholder="Please Select">
                  {filterOperatorList.map((oper, index) => (
                    <Option key={index + "op"} value={oper.OperatorServiceID}>
                      {oper.OperatorName}
                    </Option>
                  ))}
                </ANTD.Select>
              </ANTD.Form.Item>
            </ANTD.Col>
          </ANTD.Row>

          <div className="seperation-line">
            <ANTD.Row gutter={16}>
              <ANTD.Col className="gutter-row" md={8} xs={24}>
                <ANTD.Form.Item
                  label="Markup Type"
                  name="MarkupType"
                  rules={[{ required: true }]}
                >
                  <ANTD.Select
                    placeholder="Please select"
                    onSelect={() => form.setFieldsValue({ Markup: "" })}
                  >
                    <Option value={0}>Fixed</Option>
                    <Option value={1}>Percentage</Option>
                  </ANTD.Select>
                </ANTD.Form.Item>
              </ANTD.Col>
              <ANTD.Col className="gutter-row" md={8} xs={24}>
                <ANTD.Form.Item
                  label="Markup"
                  name="Markup"
                  rules={[
                    { required: true },
                    {
                      validator: (_, value) => {
                        let MarkupType = form.getFieldValue("MarkupType");
                        if (MarkupType === 0) {
                          return Promise.resolve();
                        } else if (MarkupType === 1) {
                          if (value > 100) {
                            return Promise.reject("Max Limit 100");
                          } else {
                            return Promise.resolve();
                          }
                        } else {
                          return Promise.reject("Please Select Markup Type");
                        }
                      },
                    },
                  ]}
                >
                  <ANTD.InputNumber  autocomplete="newpassword"  min={0} style={{ width: "100%" }} />
                </ANTD.Form.Item>
              </ANTD.Col>
            </ANTD.Row>
          </div>
        </ANTD.Form>
      </Modal>
    </ANTD.Layout>
  );
};
export default BusesCommissions;
