import React from "react";
import { Menu } from "antd";
export const checkRouteAccess = (link, accessList) => {
  // let temp = [];
  // for (let i = 0; i <= 46; i++) {
  //   temp.push(i);
  // }
  return true;
  const access = JSON.parse(accessList);

  if (!access) {
    return false;
  }
  let flag = false;
  if (access.indexOf(link) == -1) {
    return false;
  } else {
    return true;
  }
};
const ProtectedMenu = ({ component: Component, accessList, ...rest }) => {
  return checkRouteAccess(rest.children.props.to, accessList) ? (
    <Menu.Item {...rest} />
  ) : null;
};

export default ProtectedMenu;
