import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Layout,
  Modal,
  message,
  Popconfirm,
  Row,
  Table,
  Select,
  DatePicker,
  Tooltip,
  Collapse,
  InputNumber,
  Radio,
  Spin,
} from "antd";
import {
  PlusCircleFilled,
  EditOutlined,
  DeleteOutlined,
  CloseOutlined,
  IdcardOutlined,
  CaretRightOutlined,
  PlusOutlined,
  LoadingOutlined
} from "@ant-design/icons";
import * as ANTD from "antd";
import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import exchange from "../assets/vector-icons/exchange.png";
import moment from "moment";
import { exportExcel } from "../ExportExcel";
import "../../admin/Adminstyles.scss";
import ApiClient from "../../../src/helpers/ApiClient";
import { useAuthContext } from "../../common/providers/AuthProvider";
import AutoCompleteSelect from "../../common/AutoCompleteSelect/AutoCompleteSelect";

const { Content } = Layout;
const { Panel } = Collapse;
const Reports = () => {
  const { user } = useAuthContext();
  const { Option } = Select;
  const [searchForm] = Form.useForm();
  const [addForm] = Form.useForm();
  const [revokeForm] = Form.useForm();
  const [modalVisible, setModalVisible] = useState(false);
  const [reportsList, setReportsList] = useState([]);
  const [tableList, setTableList] = useState([]);
  const [showUpdateBtn, setShowUpdateBtn] = useState(false);
  const [currentId, setCurrentId] = useState(-1);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [activePanel, setActivePanel] = useState(["1"]);
  const [reportRole, setReportRole] = useState(0);
  const [arrivalDate, setArrivalDate] = useState(null);
  const [weekendDate, setWeekendDate] = useState(null);
  const [isManageBalanceLoading, setIsManageBalanceLoading] = useState(false);
  const [isDepositeActionLoading, setIsDepositeActionLoading] = useState(false);
  const [Visible, setVisible] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  let staticObj = {
    filter: {
      approvalStatus: "Pending",
    },
    page: 0,
  };
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
  );
  useEffect(() => {
    getReportsList(staticObj);
  }, []);

  const getReportsList = (obj) => {
    ApiClient.post("account/getdepositereports", obj)
      .then((res) => {
        if (res.isSuccess) {
          let data = res.results.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
            };
          });
          setReportsList(data);
          setTableList(data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const updateDepositStatus = (val) => {
    setIsDepositeActionLoading(true)
    ApiClient.post("account/depositstatusrequest", val).then((resp) => {
      if (resp.isSuccess) {
        setCurrentId(val.depositeId);
        getReportsList(staticObj);
        message.success(val.approvalStatus == "Approved" ? "Balance Updated Successfully" : "Deposited Request Rejected successfully", 3);
        setIsDepositeActionLoading(false)
      } else {
        message.error("Failed To Update", 3);
      }
    });
  };
  const submitChargeForm = (values) => {
    let data = {
      paymentTypeId: values.PaymentGatewayID,
      paymentName: values.PaymentType,
      type: values.Type,
      amount: Number(values.Price),
      
    };
    isEditMode ? submitUpdateMap(data) : submitMapDetails(data);
  };
  const submitUpdateMap = (formMapData) => {
    setIsLoading(true);
    ApiClient.put(`account/updatepaymenttypes/${id}`, {}, formMapData)
      .then((response) => {
        setIsLoading(false);
        if (response.isSuccess == true) {

          ANTD.message.success(" Updated SuccessFully", 3);
          Pgdetails();
          setModalVisible(false);
          setVisible(false);
          setIsEditMode(false);
          form.resetFields();
          
        }  else {
          ANTD.message.error(response.message, 3);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
  };
  const submitMapDetails = (formMapData) => {
    setIsLoading(true);
    ApiClient.post("account/savepaymenttypes", formMapData)
      .then((response) => {
        setIsLoading(false);
        if (response.isSuccess) {
          ANTD.message.success("Details Added SuccessFully", 3);
          Pgdetails();
          setModalVisible(false);
          setVisible(false);
          form.resetFields();
        } else {
          ANTD.message.error(response.message, 3);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
  };
  function choosegate(res) {
    let result = "";
    if (res == "CashFree" || res == 1) {
      result += 1;
    }
    return parseInt(result, 10);
  }
  const submitForm = (val, id, depositeId) => {
    // let depositId = depositeId;
    let data = {
      depositeId: depositeId,
      approvalStatus: val[`approvalStatus_${depositeId}`],
      remarks: val[`remarks_${depositeId}`],
    };
    updateDepositStatus(data);
  };

  const tableActions = (currentRecord, Name) => {
    return (
      <Form
        layout="vertical"
        name={`form_${currentRecord.depositeId}`}
        onFinish={(val) =>
          submitForm(val, currentRecord.userId, currentRecord.depositeId)
        }
        style={{ minWidth: "400px" }}
        autoComplete="off"
      >
        <Row gutter={12} align="middle">
          <Col md={9} xs={24}>
            <Form.Item
              label="Status"
              name={`approvalStatus_${currentRecord.depositeId}`}
              rules={[{ required: true, message: "Please select Status" }]}
            >
              <Select placeholder="Please select Service Name">
                <Option value="Approved">Approved</Option>
                <Option value="Rejected ">Rejected </Option>
              </Select>
            </Form.Item>
          </Col>

          <Col md={9} xs={24}>
            <Form.Item
              label="Remarks"
              name={`remarks_${currentRecord.depositeId}`}
              rules={[{ required: true, message: "Please enter Remarks" }]}
            >
              <Input
                autocomplete="newpassword"
                placeholder="Please Enter Remarks"
              />
            </Form.Item>
          </Col>
          {
            (currentId == currentRecord.depositeId) ? (<Col md={3} xs={24}><Spin tip="Submiting.." size="small" /></Col>) : (
              <Col md={3} xs={24}>
                <Button key="add" type="primary" htmlType="submit">
                  Submit
                </Button>
              </Col>
            )
          }

        </Row >
      </Form >
      //   </div>
      //   {/* <div className="edit-icon" style={{ textAlign: "center" }}>
      //     <Popconfirm
      //       title="Are you sure want to delete?"
      //       onConfirm={() => deleteSuppliers(Name)}
      //     >
      //       <DeleteOutlined
      //         style={{ color: "red", fontSize: "20px", textAlign: "center" }}
      //       />
      //     </Popconfirm>
      //   </div> */}
      // </div>
    );
  };

  let lastIndex = 0;
  const uniqueKey = () => {
    return lastIndex++;
  };

  const searchData = () => {
    setShowSearchBox(true);
  };
  // const getStatus = (item) => {
  //   if (item === "Approved") {
  //     return "";
  //   }
  // };


  const columns = [
    // {/*
    //  title: "Sr. No.",
    // dataIndex: "SNo",
    //sorter: (a, b) => a.SNo - b.SNo,

    // },
    // {
    //   title: "Deposit ",
    //   dataIndex: "depositeId",
    //   sorter: (a, b) => a.companyName.localeCompare(b.companyName),
    // },
    {
      title: "DateTime",
      dataIndex: "depositeDate",
      sorter: (a, b) => a.depositeDate.localeCompare(b.depositeDate),
      render: (depositeDate) => moment(depositeDate).format("DD-MM-YYYY")

    },
    {
      title: "Amnt",
      dataIndex: "amount",
      sorter: (a, b) => a.amount - b.amount,
    },
    {
      title: "Status",
      dataIndex: "approvalStatus",
      // render: (item) => getStatus(item),
      sorter: (a, b) => a.approvalStatus.localeCompare(b.approvalStatus),
    },
    {
      title: "CompanyName",
      dataIndex: "companyName",
      sorter: (a, b) => a.companyName.localeCompare(b.companyName),
    },
    {
      title: "PayMode",
      dataIndex: "paymentMode",
      sorter: (a, b) => a.paymentMode.localeCompare(b.paymentMode),
    },
    {
      title: "RefNo",
      dataIndex: "referenceNumber",
      sorter: (a, b) => a.referenceNumber.localeCompare(b.referenceNumber),
    },
    {
      title: "TrxnId",
      dataIndex: "transactionId",
      sorter: (a, b) => a.transactionId - b.transactionId,
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
    },
    {
      title: "Actions",
      render: (rec, name) =>
        rec.approvalStatus !== "Pending" ? null : tableActions(rec, name),
    },
  ];
  const paymentTableActions = (rec) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined
            onClick={() => updatePaymentForm(rec)}
            style={{ color: "#1890ff", fontSize: "20px", textAlign: "center" }}
          />
        </div>
       
      </div>
    );
  };
  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = tableList.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setTableList(searchResults);
    } else {
      setTableList(reportsList);
    }
  };

  const closeSearch = () => {
    setShowSearchBox(false);
    setTableList(reportsList);
  };

  const closeSearchInput = <CloseOutlined onClick={closeSearch} />;

  const handleExcel = () => {
    let excelData = tableList;
    excelData = excelData.map((item, i) => ({
      SNo: i + 1,
      DateTime: moment(item.depositeDate).format("DD-MM-YYYY"),
      DepositeId: item.depositeId,
      Amount: item.amount,
      ApprovalStatus: item.approvalStatus,
      CompanyName: item.companyName,
      PaymentMode: item.paymentMode,
      ReferenceNumber: item.referenceNumber,
      Remarks: item.remarks,
      TransactionId: item.transactionId,
    }));
    exportExcel(excelData, "Deposits Reports");
  };
  const searchReportsForm = (val) => {
    let obj = {
      filter: {
        fromDate: val.fromDate,
        toDate: val.toDate,
        // userId: val.reportsOf === 0 ? 1 : val.userId,
        userId: val.userId,
        approvalStatus: val.approvalStatus,
      },
      page: 0,
    };
    if (
      !obj.filter.fromDate &&
      !obj.filter.toDate &&
      !obj.filter.userId &&
      !obj.filter.approvalStatus
    ) {
      delete obj.filter;
    }
    getReportsList(obj);
  };

  const addBalanceForm = (val) => {
    setIsManageBalanceLoading(true)
    let UserId = val.userId;
    let url = "";
    let mesg = "";
    if (val.depositeType === "add") {
      url = "account/addbalance/";
      mesg = "Balance Updated successfully";
    } else {
      url = "account/revokebalance/";
      mesg = "Balance Revoked successfully";
    }
    ApiClient.post(`${url}${UserId}`, val).then((resp) => {
      if (resp.isSuccess) {
        message.success(mesg, 3);
        setCurrentId(-1);
        setIsManageBalanceLoading(false)
        addForm.resetFields();
      } else {
        message.error("Failed", 3);
      }
    });
  };
  //custome dates disable past and future dates
  const onChangeFromDate = (current, dateString) => {
    // Can not select days before today and toda
    setArrivalDate(dateString);
  };
  const onChangeToDate = (current, dateString) => {
    // Can not select days before today and today
    setWeekendDate(dateString);
  };
  ////////
  const [isEditMode, setIsEditMode] = useState(false);
  const [id, setId] = useState(-1);
  const [form] = ANTD.Form.useForm();
  const [list, setList] = React.useState([]);
  const { Header, Footer, Sider, Content } = ANTD.Layout;
  const [formLayout, setFormLayout] = React.useState("vertical");
  const showModal = () => {
    setVisible({
      visible: true,
    });
  };
  useEffect(() => {
    Pgdetails();
  }, []);
  const Pgdetails = () => {
    ApiClient.get("account/getpaymenttypes")
      .then((res) => {
        if (res.isSuccess) {
          let filterProvider = res.data.filter((item) => item.Status === 1);
         console.log("apy",res)
          let ardata = [];
          ardata = res.data.map((item) => {
            return {
              Amount: item.amount,
              PaymentType: chooseTitle(item.paymentName),
              PaymentGatewayID: chooseGate(item.paymentTypeId),
              Type: item.type,
              PaymentTypeId :item.id,
              PaymentGatewayIDupdate :item.paymentTypeId,
              PaymentTypeupdate :item.paymentName

            };
          });

          setList(ardata);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  function chooseTitle(res) {
    let result = "";
    if (res == 1) {
      result += "DebitCard";
    }
    if (res == 2) {
      result += "CreditCard";
    }
    if (res == 3) {
      result += "NetBanking";
    }
    if (res == 4) {
      result += "UPI";
    }
    // if (res == 5) {
    //   result += "Amex";
    // }
    return result;
  }
  function chooseType(res) {
    let result = "";
    if (res == 1) {
      result += "Percentage";
    }
    if (res == 2) {
      result += "Fixed";
    }
    return result;
  }
  function chooseGate(res) {
    let result = "";
    if (res == 1) {
      result += "Cashfree";
    }
    return result;
  }
  function chooseptype(res) {
    let result = "";
    if (res == "DebitCard") {
      result += 1;
    }
    if (res == "CreditCard") {
      result += 2;
    }
    if (res == "NetBanking") {
      result += 3;
    }
    if (res == "UPI") {
      result += 4;
    }
    // if (res == "Amex") {
    //   result += 5;
    // }
    if (res == 1) {
      result += 1;
    }
    if (res == 2) {
      result += 2;
    }
    if (res == 3) {
      result += 3;
    }
    if (res == 4) {
      result += 4;
    }
    // if (res == 5) {
    //   result += 5;
    // }
    return result;
  }
  const formItemLayout =
    formLayout === "vertical"
      ? {
        labelCol: { span: 24 },
        wrapperCol: { span: 23 },
      }
      : null;
  function choosetype(res) {
    let result = "";
    if (res == "Percentage") {
      result += 1;
    }
    if (res == "Fixed") {
      result += 2;
    }
    return parseInt(result, 10);
  }
  const AddCharges = () => {
    console.log("comm");
  };
  const can = () => {
    setVisible(false);
    form.resetFields();
    setIsEditMode(false);
  };
  const columnsbankbtob = [
    {
      title: "Name",
      dataIndex: "PaymentType",
      sorter: (a, b) => a.PaymentType.localeCompare(b.PaymentType),
      //render: (record) => chooseTitle(record.PaymentType)
    },
    {
      title: "Payment ID",
      dataIndex: "PaymentGatewayID",
      //render: (record) => chooseGate(record.PaymentGatewayID)
    },

    {
      title: "Charges",
      dataIndex: "Amount",
    },

    {
      title: "Type",
      dataIndex: "Type",
      //render: (record) => chooseType(record.Type)
    },

    // {
    //     title: 'Status',
    //     dataIndex: 'no',
    //     key: 'airline',
    //     render: (text, order) =>
    //     <ANTD.Switch checkedChildren="Active" unCheckedChildren="Inactive" defaultChecked />
    // },

    {
      title: "Actions",
      render: (_, rec) => paymentTableActions(rec),
    },
  ];
  const updatePaymentForm = (values) => {
    setId(values.PaymentTypeId);
    setIsEditMode(true);
    form.setFieldsValue({
      PaymentGatewayID: values.PaymentGatewayIDupdate.toString(),
      PaymentType: values.PaymentTypeupdate,
      Type: values.Type,
      Price: values.Amount,
    });
    setVisible(true);
    //setAddMarkupModal(true);
  };
  const disabledFromDate = (current) => {
    if (weekendDate != null) {
      return current.valueOf() > (new Date(weekendDate));
    }
    // return current && current > moment().endOf('day');

    return current.valueOf() > Date.now();
  }
  const disabledToDate = (current) => {
    // Can not select days before today and today
    return current.valueOf() > Date.now() || current.valueOf() < (new Date(arrivalDate));
  };
  return (
    <div className="outer-background-all">
      <div className="manage-markup-section">
        <Content className="container-fluid">
          <Row>
            <Col span={24}>
              <div className="card-bt-gap">
                <Collapse
                  bordered={false}
                  expandIcon={({ isActive }) => (
                    <Tooltip placement="top" title={isActive ? "Show" : "Hide"}>
                      <CaretRightOutlined rotate={isActive ? 90 : -90} />
                    </Tooltip>
                  )}
                  expandIconPosition="right"
                  className="panel_wrapper"
                  defaultActiveKey={["1"]}
                  activeKey={activePanel}
                  onChange={(arrKeys) => {
                    if (arrKeys.length > 1) {
                      setActivePanel(arrKeys[arrKeys.length - 1]);
                    } else {
                      setActivePanel(arrKeys);
                    }
                  }}
                >

                  <Panel header="Manage Balance" key="1">
                    {" "}
                   
                    <div className="card-bt-gap">
                      <Card bordered={false} style={{ borderRadius: "25px" }}>
                        {/* <div className="card-add-heading"> */}
                        <Form
                          layout="vertical"
                          name="addBalanceForm"
                          form={addForm}
                          onFinish={addBalanceForm}
                        >
                          <Row gutter={12} align="middle">
                            <Col md={6} xs={24}>
                              <AutoCompleteSelect
                                formItemProps={{
                                  label: "Company Name",
                                  name: "userId",
                                  rules: [
                                    {
                                      required: true,
                                      message: "Please enter User Name",
                                    },
                                  ],
                                }}
                                selectProps={{
                                  placeholder: "Please Enter User Name",
                                }}
                                api={"user/userautosuggestion/"}
                                keytext="name"
                                keyvalue="id"
                              />
                            </Col>
                            <Col md={6} xs={24}>
                              <Form.Item
                                label="Amount"
                                name="amount"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter Amount",
                                  },
                                ]}
                              >
                                <InputNumber
                                  placeholder="Please Enter Amount"
                                  style={{ width: "100%" }}
                                />
                              </Form.Item>
                            </Col>

                            <Col md={6} xs={24}>
                              <Form.Item
                                label="Payment Mode"
                                name="paymentMode"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter Payment Mode",
                                  },
                                ]}
                              >
                                <Select placeholder="Please select Payment Mode">
                                  {/* <Option value="VirtualCash">
                                      VirtualCash
                                    </Option> */}
                                  {/* <Option value="Cheque ">Cheque </Option>
                                    <Option value="Transfers">Transfers</Option>
                                    <Option value="Imps">Imps</Option>
                                    <Option value="Neft">Neft</Option>
                                    <Option value="Credit ">Credit </Option> */}
                                  <Option value="CashDepositATM">
                                    Cash Deposit ATM
                                  </Option>
                                  <Option value="CashDepositBank">
                                    Cash Deposit Bank
                                  </Option>
                                  <Option value="DirectNetTransfer">
                                    Direct NetTransfer
                                  </Option>
                                  <Option value="GooglePayTransfer">
                                    GooglePay Transfer
                                  </Option>
                                  <Option value="PhonePayTransfer">
                                    PhonePay Transfer
                                  </Option>
                                  <Option value="AmazonPayTransfer">
                                    AmazonPay Transfer
                                  </Option>
                                  <Option value="RTGS">
                                    RTGS (Real-Time Gross Settlement)
                                  </Option>
                                  <Option value="UPI">
                                    UPI (Unified Payments Interface)
                                  </Option>
                                  <Option value="AMC">
                                    AMC (Annual Maintenance Charges)
                                  </Option>
                                  <Option value="BusCancelRefund">
                                    Bus Cancel Refund
                                  </Option>
                                  <Option value="CancellationRefund">
                                    Cancellation Refund
                                  </Option>
                                  <Option value="GSTCredit">
                                    GSTCredit
                                  </Option>
                                  <Option value="TDSCredit">
                                    TDSCredit
                                  </Option>
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col md={6} xs={24}>
                              <Form.Item
                                label="Deposit Date"
                                name="depositeDate"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter Deposite Date",
                                  },
                                ]}
                              >
                                <DatePicker
                                  format="DD-MM-YYYY"
                                  placeholder="Please Enter Deposite Date"
                                  style={{ width: "100%" }}
                                />
                              </Form.Item>
                            </Col>
                            <Col md={6} xs={24}>
                              <Form.Item
                                label="Transaction Id"
                                name="transactionId"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter Transaction Id",
                                  },
                                ]}
                              >
                                <Input placeholder="Please Enter Transaction Id" />
                              </Form.Item>
                            </Col>
                            <Col md={6} xs={24}>
                              <Form.Item
                                label="Remarks"
                                name="remarks"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter Remarks",
                                  },
                                ]}
                              >
                                <Input placeholder="Please Enter Remarks" />
                              </Form.Item>
                            </Col>
                            <Col md={6} xs={24}>
                              <Form.Item
                                name="depositeType"
                                label="Choose"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please Select",
                                  },
                                ]}
                              >
                                <Radio.Group>
                                  <Radio value="add">Add</Radio>
                                  <Radio value="revoke">Revoke</Radio>
                                </Radio.Group>
                              </Form.Item>
                            </Col>
                            {
                              isManageBalanceLoading ?
                                (<Col md={6} xs={24}><Spin tip="Submiting.." size="small" /></Col>) : (
                                  <Col md={6} xs={24}>
                                    <Button
                                      key="add"
                                      type="primary"
                                      htmlType="submit"
                                      className="site-admin-btn-11"
                                    >
                                      Submit
                                    </Button>
                                  </Col>
                                )
                            }

                          </Row>
                        </Form>
                        {/* </div> */}
                      </Card>
                    </div>
                  </Panel>
                  <Panel header="Deposit Reports" key="2">
                    <div className="card-bt-gap">
                      <Card bordered={false} style={{ borderRadius: "25px" }}>
                        {/* <div className="card-add-heading"> */}
                        <Form
                          layout="vertical"
                          form={searchForm}
                          onFinish={searchReportsForm}
                        // initialValues={{
                        //   userId: "",
                        //   fromDate: "",
                        //   toDate: "",
                        //   approvalStatus: "",
                        //   page: 0,
                        // }}
                        >
                          <Row gutter={12} align="middle">
                            {/* <Col md={5} xs={24}>
                                <Form.Item
                                  label="Reports of"
                                  name="reportsOf"
                                  rules={[
                                    { required: true, message: "Required" },
                                  ]}
                                >
                                  <Select
                                    placeholder="Select"
                                    onSelect={(val) => setReportRole(val)}
                                  >
                                    <Option value={0}>Self</Option>
                                    <Option value={5}>Specific Agent</Option>
                                  </Select>
                                </Form.Item>
                              </Col>
                              {reportRole === 5 && (
                                <Col md={5} xs={24}>
                                  <AutoCompleteSelect
                                    formItemProps={{
                                      label: "User",
                                      name: "userId",
                                      rules: [
                                        {
                                          required: true,
                                          message: "Please enter User Name",
                                        },
                                      ],
                                    }}
                                    selectProps={{
                                      placeholder: "Please Enter User Name",
                                    }}
                                    api={"user/userautosuggestion/"}
                                    keytext="name"
                                    keyvalue="id"
                                  />
                                  
                                </Col>
                              )} */}
                            <Col md={6} xs={24}>
                              <AutoCompleteSelect
                                formItemProps={{
                                  label: "Company Name",
                                  name: "userId",
                                  // rules: [
                                  //   {
                                  //     required: true,
                                  //     message: "Please enter User Name",
                                  //   },
                                  // ],
                                }}
                                selectProps={{
                                  placeholder: "Please Enter User Name",
                                }}
                                api={"user/userautosuggestion/"}
                                keytext="name"
                                keyvalue="id"
                              />
                            </Col>

                            <Col md={4} xs={24}>
                              <Form.Item
                                label="From Date"
                                name="fromDate"
                              // rules={[
                              //   {
                              //     required: true,
                              //     message: "Please enter From Date",
                              //   },
                              // ]}
                              >
                                <DatePicker
                                  format="YYYY-MM-DD"
                                  placeholder="Please Enter From Date"
                                  style={{ width: "100%" }}
                                  onChange={(date, dateString) =>
                                    onChangeFromDate(date, dateString)
                                  }
                                  disabledDate={disabledFromDate}
                                />
                              </Form.Item>
                            </Col>
                            <Col md={4} xs={24}>
                              <Form.Item
                                label="To Date"
                                name="toDate"
                              // rules={[
                              //   {
                              //     required: true,
                              //     message: "Please enter To Date",
                              //   },
                              // ]}
                              >
                                <DatePicker
                                  format="YYYY-MM-DD"
                                  placeholder="Please Enter To Date"
                                  style={{ width: "100%" }}
                                  disabledDate={disabledToDate}
                                  onChange={(date, dateString) =>
                                    onChangeToDate(date, dateString)

                                  }
                                />
                              </Form.Item>
                            </Col>

                            <Col md={4} xs={24}>
                              <Form.Item
                                label="Status"
                                name="approvalStatus"
                              // rules={[
                              //   {
                              //     required: true,
                              //     message: "Please enter Status",
                              //   },
                              // ]}
                              >
                                <Select placeholder="Please select Status">
                                  <Option value="Pending">Pending</Option>
                                  <Option value="Rejected ">Rejected </Option>
                                  <Option value="Revoked">Revoked</Option>
                                  <Option value="Approved">Approved</Option>
                                </Select>
                              </Form.Item>
                            </Col>

                            <Col md={2} xs={24}>
                              <Button
                                key="add"
                                type="primary"
                                htmlType="submit"
                              >
                                Search
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                        {reportsList.length > 0 ? (
                          <>
                            {/* <div className="card-add-heading"> */}
                            <div className="rows-count">
                              <h5>View Deposit Reports</h5>
                              <p>{tableList.length} rows found !</p>
                            </div>
                            <div className="action-images">
                              {showSearchBox && (
                                <Input
                                  autocomplete="newpassword"
                                  placeholder="Search"
                                  onChange={(e) => searchTableData(e)}
                                  suffix={closeSearchInput}
                                  style={{
                                    padding: "0px 12px",
                                    maxWidth: "250px",
                                  }}
                                />
                              )}
                              &nbsp;&nbsp;
                              <img
                                src={search}
                                alt="search"
                                onClick={() => searchData()}
                              />
                              <img
                                src={excel}
                                alt="excel"
                                onClick={() => handleExcel()}
                              />
                              <img
                                src={exchange}
                                alt="exchange"
                                onClick={() => {
                                  setTableList([]);
                                  getReportsList(staticObj);
                                }}
                              />
                              {/* <p className="add-deposit-icon" onClick={handleModal}>
                    <PlusCircleFilled />
                  </p> */}
                            </div>
                            {/* </div> */}

                            <Table
                              scroll={{ x: true }}
                              bordered
                              rowKey={uniqueKey}
                              dataSource={tableList}
                              columns={columns}
                              pagination={{
                                defaultPageSize: 25,
                                showSizeChanger: true,
                                pageSizeOptions: ["25", "50", "100", "125"],
                              }}
                            />
                          </>
                        ) : (
                          <b>No Results Found!</b>
                        )}
                        {/* </div> */}
                      </Card>
                    </div>
                  </Panel>
                  <Panel header="Payment Gateway Charges" key="3">
                    <div className="card-bt-gap">
                      <Card bordered={false} style={{ borderRadius: "25px" }}>
                        <div className="card-add-heading">
                          {/* <div className="table-heading-count"> */}
                          <div className="rows-count">
                            <div>
                              <h5>
                                View Payment Type Charges{" "}
                                {/* <HelpInfoHelper screenName={"/admin/b2b"} /> */}
                              </h5>
                              <p>{list.length} rows found !</p>
                            </div>
                          </div>
                          <div className="action-images">
                            {showSearchBox && (
                              <ANTD.Input
                                placeholder="Search"
                                onChange={(e) => searchTableData(e)}
                                suffix={closeSearchInput}
                                style={{ padding: "0px 12px" }}
                              />
                            )}
                            &nbsp;&nbsp;
                            <img
                              src={search}
                              alt="search"
                              onClick={(e) => searchData()}
                            />
                            <img src={excel} alt="excel" onClick={handleExcel} />
                            {/* <img src={pdf} alt="pdf" /> */}
                            {/* <img src={word} alt="word" /> */}
                            {/*  <img src={exchange} alt="exchange" /> */}
                            <p className="add-icon" onClick={showModal}>
                              <PlusOutlined />
                            </p>
                          </div>
                          {/* </div> */}
                        </div>

                        <div>
                          {/* onChange={onChange} expandedRowRender= {rowExpand}*/}
                          <ANTD.Table
                            className="table-scroll-none"
                            bordered
                            dataSource={list}
                            columns={columnsbankbtob}
                            pagination={{
                              defaultPageSize: 25,
                              showSizeChanger: true,
                              pageSizeOptions: ["25", "50", "100", "125"],
                            }}
                          />

                          {/* <ANTD.Modal visible={this.state.visible} onCancel={closeModal}>
                                                <h6>This is the modal bodyyyyy</h6>
                                        </ANTD.Modal> */}
                        </div>
                      </Card>
                    </div>
                  </Panel>
                </Collapse>
              </div>
            </Col>
          </Row>
        </Content>
      </div>
      <Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>Add Payment Type</strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header admin-bc-level modal-header-bg12"
        visible={Visible}
        onOk={(e) => setVisible(false)}
        onCancel={(e) => can()}
        footer={[
          <div>
            {/* <ANTD.Button key="add" type="primary" onClick={form.submit} htmlType="submit">Add</ANTD.Button> */}
            <ANTD.Button key="add" type="primary" onClick={form.submit}>
            {isLoading ? (
                            <Spin indicator={antIcon} />
                          ) : (
                            isEditMode ? "Update" : "Add"
                          )}
            
            </ANTD.Button>
          </div>,
        ]}
      >
        <ANTD.Form
          {...formItemLayout}
          form={form}
          layout={formLayout}
          onFinish={submitChargeForm}
          initialValues={{ layout: formLayout }}
        >
          <ANTD.Row>
            <ANTD.Col md={24} xs={24}>
              <ANTD.Form.Item
                label="Payment Gateway"
                rules={[{ required: true }]}
                name="PaymentGatewayID"
              >
                <ANTD.Select
                  size={"large"}
                  prefix={<IdcardOutlined className="site-form-item-icon" />}
                  placeholder="Please Select"
                >
                  <ANTD.Select.Option value="1">CashFree</ANTD.Select.Option>
                  {/* <ANTD.Select.Option value="2">Razorpay</ANTD.Select.Option> */}
                </ANTD.Select>
              </ANTD.Form.Item>
            </ANTD.Col>

            <ANTD.Col md={24} xs={24}>
              <ANTD.Form.Item
                label="Payment Type"
                rules={[{ required: true }]}
                name="PaymentType"
              >
                <ANTD.Select
                  size={"large"}
                  prefix={<IdcardOutlined className="site-form-item-icon" />}
                  placeholder="Please Select"
                >
                  <ANTD.Select.Option value="1">Debit Card</ANTD.Select.Option>
                  <ANTD.Select.Option value="2">
                    Credit Card
                  </ANTD.Select.Option>
                  <ANTD.Select.Option value="3">
                    Net Banking
                  </ANTD.Select.Option>
                  <ANTD.Select.Option value="4">UPI</ANTD.Select.Option>
                </ANTD.Select>
              </ANTD.Form.Item>
            </ANTD.Col>

            <ANTD.Col md={24} xs={24}>
              <ANTD.Form.Item
                label="Charges"
                name="Price"
                rules={[
                  {
                    required: true,
                    message: "Please input your Charges",
                  },
                ]}
              >
                <ANTD.Input placeholder="Charges" size={"large"} />
              </ANTD.Form.Item>
            </ANTD.Col>

            <ANTD.Col md={12} xs={24}>
              <Form.Item
                label="Type"
                name="Type"
                rules={[{ required: true, message: "Type is required" }]}
              >
                <Radio.Group>
                  <Radio value="percentage">Percentage</Radio>
                  <Radio value="amount">Fixed</Radio>
                </Radio.Group>
              </Form.Item>
            </ANTD.Col>
          </ANTD.Row>
        </ANTD.Form>
      </Modal>
    </div>
  );
};
export default Reports;
