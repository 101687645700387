import React from "react";
import { Card, Col, Layout, Row } from "antd";
import "./ApiReference.scss";
const { Content } = Layout;

const ApiReference = () => {
  return (
    <div className="outer-background-all">
      <div className="manage-markup-section">
        <Content className="container-fluid">
          <div className="card-bt-gap">
            <Card bordered={false} style={{borderRadius:"25px"}}>
              <Row className="card-add-heading">
                <Col md={24} sm={24} xs={24}>
                  <div className="card-bt-gap">
                    <h5 className="mb-1">Api Reference</h5>
                    <iframe
                      //src="https://bapi.etravos.in/swagger/index.html"
                      src="http://95.217.1.95:1621/swagger/index.html"
                      name="apiReference"
                      className="i-frame"
                    ></iframe>
                  </div>
                </Col>
              </Row>
            </Card>
          </div>
        </Content>
      </div>
    </div>
  );
};
export default ApiReference;
