import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Layout,
  Modal,
  message,
  Popconfirm,
  Row,
  Table,
  Select,
  DatePicker,
} from "antd";
import {
  PlusCircleFilled,
  EditOutlined,
  DeleteOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import exchange from "../assets/vector-icons/exchange.png";
import AutoCompleteSelect from "../../common/AutoCompleteSelect/AutoCompleteSelect";
import { exportExcel } from "../ExportExcel";
import "../../admin/Adminstyles.scss";
import ApiClient from "../../../src/helpers/ApiClient";
const { Content } = Layout;

const Reports = () => {
  const { Option } = Select;
  const [form] = Form.useForm();
  const [modalVisible, setModalVisible] = useState(false);
  const [siteAdminList, setSiteAdminList] = useState([]);
  const [statementList, setStatementList] = useState([]);
  const [tableList, setTableList] = useState([]);
  const [showUpdateBtn, setShowUpdateBtn] = useState(false);
  const [currentId, setCurrentId] = useState(-1);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [table, setTable] = useState(false);
  const [reportRole, setReportRole] = useState(0);
  const [arrivalDate, setArrivalDate] = useState(null);
  const [weekendDate, setWeekendDate] = useState(null);
  let userId = 1;
  let pageObj = {
    page: 0,
  };
  useEffect(() => {
    getSiteAdminList();
    getAccountStatements(userId, pageObj);
  }, []);

  const TransactionType = (txType) => {
    
    let TransactionTypeName = ''
    switch (txType) {
      case 1: {
        TransactionTypeName = "Deposite";
        break;
      }
      case 2: {
        TransactionTypeName = "BusBooking";
        break;
      }
      case 3: {
        TransactionTypeName = "BusTicketCancel";
        break;
      }
      case 4: {
        TransactionTypeName = "Flight";
        break;
      }
      default: TransactionTypeName = ''
    }
    return TransactionTypeName
  }
  const getSiteAdminList = () => {
    let data = {
      page: 0,
    };
    ApiClient.post("user/getuserslist/list", data)
      .then((res) => {
        if (res.isSuccess) {
          let data = res.results.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
            };
          });
          setSiteAdminList(data);
        }
      })
      .catch((e) => {
        console.log("api error", e);
      });
  };

  const getAccountStatements = (UserId, obj) => {
    ApiClient.post(`account/accountstatement/${UserId}`, obj)
      .then((res) => {
        if (res.isSuccess) {
          let data = res.results.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
              TransactionTypeName: TransactionType(item.transactionType)
            };
          });
          setStatementList(data);
          setTableList(data);
          // setTable(true);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleModal = () => {
    if (showUpdateBtn) {
      setShowUpdateBtn(false);
      form.resetFields();
    }
    setModalVisible(true);
  };

  const submitForm = (val) => {
    let userId = val.reportsOf === 0 ? 1 : val.userId;

    let obj = {
      filter: {
        fromDate: val.fromDate,
        toDate: val.toDate,
      },
      page: 0,
    };
    if (!obj.filter.fromDate && !obj.filter.toDate) {
      delete obj.filter;
    }
    if (val) {
      getAccountStatements(userId, obj);
    }
  };

  const tableActions = (currentRecord, Name) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined
            onClick={() => editCurrentItem(currentRecord, Name)}
            style={{ color: "#1890ff", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        {/* <div className="edit-icon" style={{ textAlign: "center" }}>
          <Popconfirm
            title="Are you sure want to delete?"
            onConfirm={() => deleteSuppliers(Name)}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </Popconfirm>
        </div> */}
      </div>
    );
  };

  const editCurrentItem = (id, data) => {
    setShowUpdateBtn(true);
    setCurrentId(id);
    form.resetFields();
    setModalVisible(true);
  };

  let lastIndex = 0;
  const uniqueKey = () => {
    return lastIndex++;
  };

  const searchData = () => {
    setShowSearchBox(true);
  };

  const columns = [
    // {
    //   title: "Sr. No.",
    //   dataIndex: "SNo",
    //   sorter: (a, b) => a.SNo - b.SNo,
    // },
    {
      title: "Ref No ",
      dataIndex: "referenceNo",
      sorter: (a, b) => a.referenceNo.localeCompare(b.referenceNo),
    },
    {
      title: "CompanyName",
      dataIndex: "companyName",
      sorter: (a, b) => a.companyName.localeCompare(b.companyName),
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      sorter: (a, b) => a.remarks.localeCompare(b.remarks),
    },

    {
      title: "Bef Bal",
      dataIndex: "beforeBalance",
      sorter: (a, b) => a.beforeBalance - b.beforeBalance,
    },
    {
      title: "Amount",
      dataIndex: "transactionAmount",
    },
    {
      title: "Aft Bal",
      dataIndex: "afterBalance",
      sorter: (a, b) => a.afterBalance - b.afterBalance,
    },

    {
      title: "Txn Type",
      dataIndex: "TransactionTypeName",
    },

    {
      title: "GST",
      dataIndex: "gst",
    },

    {
      title: "TDS",
      dataIndex: "tds",
    },

    {
      title: "Is Debit",
      dataIndex: "isDebit",
      render: (isDebit, records) => isDebit ? "True" : "False"
    },


   // {
     // title: "Remarks",
      //dataIndex: "remarks",
    //},
    //{
    //  title: "TransactionId",
    //  dataIndex: "transactionId",
    //  sorter: (a, b) => a.transactionId - b.transactionId,
   // },
   // {
     // title: "Actions",
     // render: (id, name) => tableActions(id, name),
    //},
  ];

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = tableList.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setTableList(searchResults);
    } else {
      setTableList(statementList);
    }
  };

  const closeSearch = () => {
    setShowSearchBox(false);
    setTableList(statementList);
  };

  const closeSearchInput = <CloseOutlined onClick={closeSearch} />;

  const handleExcel = () => {
    let excelData = tableList;
    excelData = excelData.map((item, i) => ({
      SNo: i + 1,
      afterBalance: item.afterBalance,
      beforeBalance: item.beforeBalance,
      companyName: item.companyName,
      gst: item.gst,
      isDebit: item.isDebit,
      referenceNo: item.referenceNo,
      remarks: item.remarks,
      tds: item.tds,
      transactionAmount: item.transactionAmount,
      transactionType: item.transactionType,
      userId: item.userId,
    }));
    exportExcel(excelData, "Account Statement");
  };

  //custome dates disable past and future dates
const onChangeFromDate = (current, dateString) => {
  // Can not select days before today and toda
  setArrivalDate(dateString);
};
const onChangeToDate = (current, dateString) => {
  // Can not select days before today and today
  setWeekendDate(dateString);
};
const disabledFromDate = (current) => {
  if (weekendDate != null) {
      return current.valueOf() > (new Date(weekendDate));
  }
  // return current && current > moment().endOf('day');

  return current.valueOf() > Date.now();
}
const disabledToDate = (current) => {
  // Can not select days before today and today
  return current.valueOf() > Date.now() || current.valueOf() < (new Date(arrivalDate));
};
  return (
    <div className="outer-background-all">
      <div className="manage-markup-section">
        <Content className="container-fluid">
          <div className="card-bt-gap">
            <Card bordered={false} style={{borderRadius:"25px"}}>
              {/* <div className="card-add-heading"> */}
                <h5>Statements</h5>
                <Form
                  layout="vertical"
                  form={form}
                  onFinish={submitForm}
                // initialValues={{
                //   fromDate: "",
                //   toDate: "",
                //   page: 0,
                // }}
                >
                  <Row gutter={12} align="middle">
                    <Col md={6} xs={24}>
                      <Form.Item
                        label="Reports of"
                        name="reportsOf"
                        rules={[{ required: true, message: "Required" }]}
                      >
                        <Select
                          placeholder="Select"
                          onSelect={(val) => setReportRole(val)}
                        >
                          <Option value={0}>Self</Option>
                          <Option value={5}>Specific Company</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    {reportRole === 5 && (
                      <Col md={6} sm={12} xs={24}>
                        <AutoCompleteSelect
                          formItemProps={{
                            label: "Company Name",
                            name: "userId",
                            rules: [
                              {
                                required: true,
                                message: "Please enter User Name",
                              },
                            ],
                          }}
                          selectProps={{
                            placeholder: "Please Enter User Name",
                          }}
                          api={"user/userautosuggestion/"}
                          keytext="name"
                          keyvalue="id"
                        />
                        
                      </Col>
                    )}
                    <Col md={4} sm={12} xs={24}>
                      <Form.Item
                        label="From Date"
                        name="fromDate"
                      // rules={[
                      //   { required: true, message: "Please enter From Date" },
                      // ]}
                      >
                        <DatePicker
                          format="YYYY-MM-DD"
                          placeholder="Please Enter From Date"
                          style={{ width: "100%" }}
                          onChange={(date, dateString) =>
                            onChangeFromDate(date, dateString)
                        }
                        disabledDate={disabledFromDate}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={4} sm={12} xs={24}>
                      <Form.Item
                        label="To Date"
                        name="toDate"
                      // rules={[
                      //   { required: true, message: "Please enter To Date" },
                      // ]}
                      >
                        <DatePicker
                          format="YYYY-MM-DD"
                          placeholder="Please Enter To Date"
                          style={{ width: "100%" }}
                          disabledDate={disabledToDate}
                          onChange={(date, dateString) =>
                              onChangeToDate(date, dateString)

                          }
                        />
                      </Form.Item>
                    </Col>

                    <Col span={2}>
                      <Button
                        key="add"
                        className="site-admin-btn-11"
                        type="primary"
                        onClick={form.submit}
                      >
                        Search
                      </Button>
                    </Col>
                  </Row>
                </Form>
                {tableList.length > 0 ? (
                  <>
                    <div className="card-add-heading">
                      <div className="rows-count">
                        <h5>View Account Statements</h5>
                        <p>{tableList.length} rows found !</p>
                      </div>
                      <div className="action-images">
                        {showSearchBox && (
                          <Input
                            autocomplete="newpassword"
                            placeholder="Search"
                            onChange={(e) => searchTableData(e)}
                            suffix={closeSearchInput}
                            style={{ padding: "0px 12px", maxWidth: "249px" }}
                          />
                        )}
                        &nbsp;&nbsp;
                        <img
                          src={search}
                          alt="search"
                          onClick={() => searchData()}
                        />
                        <img
                          src={excel}
                          alt="excel"
                          onClick={() => handleExcel()}
                        />
                        <img
                          src={exchange}
                          alt="exchange"
                          onClick={() => {
                            setTableList([]);
                            getAccountStatements(userId, pageObj);
                          }}
                        />
                        {/* <p className="add-deposit-icon" onClick={handleModal}>
                    <PlusCircleFilled />
                  </p> */}
                      </div>
                    </div>

                    <Table
                      scroll={{ x: true }}
                      bordered
                      rowKey={uniqueKey}
                      dataSource={tableList}
                      columns={columns}
                      pagination={{
                        defaultPageSize: 25,
                        showSizeChanger: true,
                        pageSizeOptions: ["25", "50", "100", "125"],
                      }}
                    />
                  </>
                ) : null}
              {/* </div> */}
            </Card>
          </div>
        </Content>
      </div>
    </div>
  );
};
export default Reports;
