import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import PaymentFailure from "../../admin/PaymentFailure/PaymentFailure";
import PaymentSucces from "../../admin/PaymentSuccess/PaymentSuccess";
import PrintTicket from "../../common/PrintTicket/PrintTicket";
import { FlightContext } from "../../common/providers/Flights/FlightContext";
import TicketCancel from "../../common/TicketCancel/TicketCancel";
import AboutUS from "../../components/AboutUS/AboutUs";
import Bookingreports from "../../components/Bookingreports/Bookingreports";
import UserBusTicket from "../../components/Bookingreports/UserTickets/UserBusTicket";
import UserFlightTicket from "../../components/Bookingreports/UserTickets/UserFlightTicket";
import Busticketsuccess from "../../components/Busticketsuccess/Busticketsuccess";
import Careers from "../../components/Careers/Careers";

import CheckInformation from "../../components/CheckInformation/CheckInformation";
import CombinedHome from "../../components/CombinedHome/CombinedHome";
import CombinedResults from "../../components/CombinedResults/CombinedResults";
import ContactUsForm from "../../components/ContactUsForm/ContactUsForm";
import Disclaimer from "../../components/Disclaimer/Disclaimer";
import Error404 from "../../components/ErrorPages/Error404";
import NotFound from "../../components/ErrorPages/NotFound";
import Unauthorized from "../../components/ErrorPages/Unauthorized";
import Faqs from "../../components/Faqs/Faqs";
import FlightEnquiry from "../../components/FlightEnquiry/FlightEnquiry";
import FlightInvoice from "../../components/Flights-Invoice/FlightsInvoice";
import Flightticketsuccess from "../../components/Flightticketsuccess/Flightticketsuccess";
import Investorrelations from "../../components/Investorrelations/Investorrelations";
import Mytrips from "../../components/Mytrips/Mytrips";
import Offers from "../../components/Offers/Offers";
import OurTeam from "../../components/OurTeam/OurTeam";
import Privacypolicy from "../../components/Privacypolicy/Privacypolicy";
import Profile from "../../components/Profile/Profile";
import Refundpolicy from "../../components/Refundpolicy/Refundpolicy";
import Termsofconditions from "../../components/Termsofconditions/Termsofconditions";
import Testimonials from "../../components/Testimonials/Testimonials";
import ProtectedRoute from "../../helpers/RouteProtection";
import BusInvoice from "../../pages/Buses/Invoice/BusInvoice";
import BusReview from "../../pages/Buses/Review/BusReview";
import PgrequestTest from "../../PgRequestTest";
import { FlightContextProvider } from "../providers/Flights/FlightContext";
import FlightReview from "./../../components/Flights-Review/Flights-Review";
import FlightCheckout from "./../../components/FlightsCheckout/FlightsCheckout";
import Login from "./../../components/Login/Login";
import Checkout from "./../../pages/Buses/Checkout/Checkout";
import Socialresponsibility from "../../components/Socialresponsibility/Socialresponsibility";
import PgReturnUrl from "../../helpers/PgReturnUrl";

const UserNavigations = ({ gscontext }) => {
  return (
    <FlightContextProvider>
      <FlightContext.Consumer>
        {(flightContext) => (
          <Switch>
            <Route
              exact
              path={"/"}
              render={() => (
                <CombinedHome
                  gscontext={gscontext}
                  flightContext={flightContext}
                />
              )}
            />

            <Route
              exact
              path={"/combinedresults"}
              render={() => (
                <CombinedResults
                  gscontext={gscontext}
                  flightContext={flightContext}
                />
              )}
            />
            <Route
              exact
              path={"/login"}
              render={() => <Login gscontext={gscontext} />}
            />
            <Route
              exact
              path={"/bus-checkout"}
              render={() => <Checkout gscontext={gscontext} />}
            />
            <Route
              exact
              path={"/flight-checkout"}
              render={() => <FlightCheckout flightContext={flightContext} />}
            />
            <Route
              exact
              path={"/flight-review"}
              render={() => <FlightReview flightContext={flightContext} />}
            />
            <Route
              exact
              path={"/flight-invoice"}
              render={() => <FlightInvoice flightContext={flightContext} />}
            />
            <Route
              exact
              path={"/bus-review"}
              render={() => <BusReview gscontext={gscontext} />}
            />

            <Route exact path={"/bus-invoice"} component={BusInvoice} />
            <Route exact path={"/checkInfo"} component={CheckInformation} />
            <Route exact path={"/ticketcancel"} component={TicketCancel} />

            <Route exact path={"/printticket"} component={PrintTicket} />

            <Route exact path={"/ourteam"} component={OurTeam} />

            <ProtectedRoute
              exact
              path={"/bookingreports"}
              component={Bookingreports}
            />

            <Route exact path={"/testimonials"} component={Testimonials} />

            <Route exact path={"/faqs"} component={Faqs} />

            <Route exact path={"/mytrips"} component={Mytrips} />
            <Route
              exact
              path={"/flightticketsuccess"}
              component={Flightticketsuccess}
            />

            <Route
              exact
              path={"/Busticketsuccess"}
              component={Busticketsuccess}
            />

            <Route
              exact
              path={"/termsofconditions"}
              component={Termsofconditions}
            />

            <Route exact path={"/privacypolicy"} component={Privacypolicy} />

            <Route exact path={"/disclaimer"} component={Disclaimer} />

            <Route exact path={"/careers"} component={Careers} />

            <Route exact path={"/Refundpolicy"} component={Refundpolicy} />

            <Route
              exact
              path={"/Investorrelations"}
              component={Investorrelations}
            />

            <Route exact path={"/offers"} component={Offers} />

            <ProtectedRoute exact path={"/profile"} component={Profile} />
            <Route
              exact
              path={"/Socialresponsibility"}
              component={Socialresponsibility}
            />
            {/* ======== ROuting for Error pages ============ */}
            <Route exact path={"/404"} component={Error404} />

            {/*  ============== ROuting for Admin ============ */}

            <Route exact path={"/pg_test"} component={PgrequestTest} />
            <Route exact path={"/flight_ticket"} component={UserFlightTicket} />
            <Route exact path={"/bus_ticket"} component={UserBusTicket} />

            <Route exact path={"/contact_us_form"} component={ContactUsForm} />
            <Route exact path={"/flightenquiry"} component={FlightEnquiry} />

            <Route exact path={"/pg_failure"} component={PaymentFailure} />
            <Route exact path={"/pg_success"} component={PaymentSucces} />
            <Route exact path={"/AboutUs"} component={AboutUS} />
          
            <Route exact path="/unauthorized" component={Unauthorized} />
            <Route component={NotFound} />
          </Switch>
        )}
      </FlightContext.Consumer>
    </FlightContextProvider>
  );
};

export default withRouter(UserNavigations);
