import React, { useState, useRef } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  message,
  Modal,
  Statistic,
  Card,
} from "antd";
import { useHistory } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/auth";
import { useAuthContext } from "../../common/providers/AuthProvider";
import ApiClient from "../../helpers/ApiClient";
import BusApiClient from "../../helpers/BusApiClient";
import globe from "../../assets/images/globe-trus-1.png";
import otpMob from "../../assets/images/mobile-otp-k1.png";
import sendImg from "../../assets/images/send-otp-2.png";
import successImg from "../../assets/images/success-otp.png";
import sendImg2 from "../../assets/images/send-otp-1.png";
import gmailLogo from "../../assets/images/mail-new-img.png";
import logintop from "../../assets/images/login-top.png";

import "./Login.scss";
import EncHelper from "../../helpers/EncHelper";
import { firebaseConfig } from "./FireBaseConfig";
import EtravosBack from "../../assets/images/etravosLogin.png";
import EtravosLogo from "../../assets/images/etravosLogo.png";

const Login = (props) => {
  const [userID, setUserID] = useState(-1);
  const { setUser, setAccessToken, setBusAccessToken } = useAuthContext();

  const { updateIsLogin } = props.gscontext;
  const setChangeLoginFlag = (value) => updateIsLogin("flag", value);
  const setChangeDashboardFlag = (value) => updateIsLogin("role", value);

  const [isLoginForm, setIsLoginForm] = useState(true);
  const updateDashboardFlag = (val) => {
    setChangeDashboardFlag(val);
  };
  const onFinish = (data) => {
    data = {
      username: data.username,
      password: data.password,
      ipAddress: null,
      loginType: "Web" //"API for Bus Login"
    };
    ApiClient.post("user/login", data)
      .then((res) => {
        if (res.isSuccess === true) {
          setUser(res.data.user);
          setAccessToken(res.data.tokenId);
          // history.push("/");

          // if (res.data.user.roleId === 1 || res.data.user.roleId === 2 || res.data.user.roleId === 5) {
          //   if (res.data.user.isActive === false && res.data.user.roleId === 2) {
          //     message.info("User is Inactive")
          //   }
          //   else {
          //     // let screens = [];
          //     // res.data.user.Screens.forEach((element) => {
          //     //   screens.push(element.url);
          //     // });
          //     // EncHelper.setItem("access_screens", JSON.stringify(screens));
          //     updateDashboardFlag(true);
          //   }
          // }

          if (res.data.user.isActive === false) {
            message.info("User is Inactive")
          }
          else {
            // let screens = [];
            // res.data.user.Screens.forEach((element) => {
            //   screens.push(element.url);
            // });
            // EncHelper.setItem("access_screens", JSON.stringify(screens));
            updateDashboardFlag(true);
          }

          // message.success(res.message);
        } else if (res.status == 409) {
          message.error(res.message);
        } else {
          message.error(res.message);
        }
      })
      .catch((e) => { });
    // BusApiClient.post("bus/login", data)
    //   .then((res) => {
    //     if (res) {
    //       setBusAccessToken(res.accessToken);
    //       // message.success("Success", 3);
    //     } else {
    //       message.error("Error in Bus Login ");
    //     }
    //   })
    //   .catch((e) => {});
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const showForgotForm = () => {
    setIsLoginForm(false);
  };

  const submitForm = (data) => {
    ApiClient.get(`user/forgotpassword?Username=${data.EmailID}`)
      .then((res) => {
        if (res.isSuccess === true) {
          message.success(res.message);
          setIsLoginForm(true);
        } else {
          message.error("User Not Found");
        }
      });
  };

  return (
    <div className="login-container">
      <div className="login-width-panel">
        <Row className="align-items-center vh-100 login-direction justify-content-center">
          <Col md={9} sm={24} xs={24}>
            <div className="login-wrapper">
              <Card className="login-card">
                {isLoginForm ? (
                  <Form
                    layout="vertical"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                  >
                    <Row>
                      <Col md={24} xs={24} className="py-2">
                        <img
                          className="login-otp-img"
                          src={logintop}
                          alt="search-img"
                        />
                        <div className="bc-admin-21 d-flex justify-content-center">
                          <img
                            className=""
                            src={EtravosLogo}
                            alt="search-img"
                          />
                        </div>
                      </Col>

                      <Col md={24} xs={24} className="otp-shadow-1">
                        <Form.Item
                          className="otp-margin-bottom"
                          label="User Name"
                          name="username"
                          rules={[
                            {
                              required: true,
                              message: "Please input your username!",
                            },
                          ]}
                        >
                          <Input placeholder="User Name" />
                        </Form.Item>

                        <Form.Item
                          className="otp-margin-bottom"
                          label="Password"
                          name="password"
                          rules={[
                            {
                              required: true,
                              message: "Please input your password!",
                            },
                          ]}
                        >
                          <Input.Password placeholder="Password" />
                        </Form.Item>
                      </Col>

                      <Col md={24} xs={24}>
                        <Button className="otp-btn" htmlType="submit">
                          Log In
                        </Button>
                      </Col>
                      <Col md={24} xs={24} className="mt-1 text-center">
                        <a
                          className="login-form-forgot "
                          onClick={showForgotForm}
                        >
                          Forgot Password !
                        </a>
                      </Col>
                    </Row>
                  </Form>
                ) : (
                  <Form
                    onFinish={submitForm}
                    autoComplete="off"
                    layout="vertical"
                  >
                    <Row>
                      <Col md={24} xs={24} className="py-2">
                        <img
                          className="login-otp-img"
                          src={logintop}
                          alt="search-img"
                        />
                        <div className="bc-admin-21">
                          <h5>Forgot Password</h5>
                          <p>Enter Email Id</p>
                        </div>
                      </Col>

                      <Col md={24} xs={24} className="otp-shadow-1">
                        <Form.Item
                          label="EMAIL ID"
                          name="EmailID"
                          rules={[
                            {
                              required: true,
                              message: "Please input your username!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col md={24} xs={24}>
                        <Button className="otp-btn" htmlType="submit">
                          Submit
                        </Button>
                      </Col>
                      <Col md={24} xs={24} className="mt-1 text-center">
                        <a
                          className="login-form-forgot "
                          onClick={() => setIsLoginForm(true)}
                        >
                          Back To Login
                        </a>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Card>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Login;
