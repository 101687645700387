import React from "react";
import { Route, Switch } from "react-router-dom";
import FlightTicket from "../../admin/FlightTicket/FlightTicket";
import Error404 from "../../components/ErrorPages/Error404";
import AdminRoute from "../../helpers/AdminProtection";
import Promocode from "../../admin/Promo/Promo";
import AccessPermissions from "../../admin/AccessPermissions/AccessPermissions";
import BusesCommissions from "../../admin/AdminMarkup/BusesCommissions";
import FlightsMarkup from "../../admin/AdminMarkup/FlightsCommissions";
import AdminUser from "../../admin/AdminUser/AdminUser";
import Banners from "../../admin/Banners/Banners";
import CMS from "../../admin/CMS/cms";
import ContactForm from "../../admin/ContactForms/ContactForm";
import Dashboard from "../../admin/Dashboard/Dashboard";
import Charts from "../../admin/Dashboard/Dashboard";
import FlightEnquires from "../../admin/FlightEnquires/FlightEnquires";
import APILogs from "../../admin/Logs/APIAccessLogs";
import DBLogs from "../../admin/Logs/DBLogs";
import PGLogs from "../../admin/Logs/PGLogs";
import BusOperatorsService from "../../admin/OperatorsService/BusOperatorsService";
import FlightOperatorsService from "../../admin/OperatorsService/FlightOperatorsService";
import Admin from "../../admin/Permissions/Admin/Admin";
import Role from "../../admin/Role/Role";
import Membership from "../../admin/Membership/Membership";
import Siteadmin from "../../admin/Siteadmin/Siteadmin";
import SiteadminDeposit from "../../admin/Siteadmin/SiteadminDeposit";
import SiteadminStatement from "../../admin/Siteadmin/SiteadminStatement";
import SiteadminQuickHelp from "../../admin/Siteadmin/SiteadminQuickHelp";
import Category from "../../admin/Category/Category";
import Screens from "../../admin/Screens/Screens";
import ScreenMapping from "../../admin/ScreenMapping/ScreenMapping";
import BusCommissions from "../../admin/BusCommissions/BusCommissions";
import Operators from "../../admin/Operators/Operators";
import Suppliers from "../../admin/Suppliers/Suppliers";
import Reports from "../../admin/Reports/Reports";
import Statements from "../../admin/Statements/Statements";
import Settings from "../../admin/Settings/Settings";
import Siteusers from "../../admin/Siteusers/Siteusers";
import SuperBusTicket from "../../admin/SuperAdminBookingReports/SuperReportTickets/SuperBusTicket";
import SuperFlightTicket from "../../admin/SuperAdminBookingReports/SuperReportTickets/SuperFlightTicket";
import AddProvider from "../../admin/SupplierConfiguration/AddProvider";
import ProviderApi from "../../admin/SupplierConfiguration/ProviderApi";
import TravelCalendar from "../../admin/TravelCalendar/TravelCalendar";
import SubscriberReport from "../../admin/SubscriberReport/SubscriberReport";
import Unauthorized from "../../components/ErrorPages/Unauthorized";
import NotFound from "../../components/ErrorPages/NotFound";
import Createlocation from "../../admin/Mapping/Createlocation";
import Updatelocation from "../../admin/Mapping/Updatelocation";
import BusBooking from "../../admin/Buses/BusBooking/BusBooking";
import BusResults from "../../admin/Buses/BusResults/BusResults";
import CancelRequest from "../../admin/CancelRequest/CancelRequest";
import BusCheckout from "./../../admin/Buses/Checkout/BusCheckout";
import BusReview from "./../../admin/Buses/Review/BusReview";
import BusSuccess from "./../../admin/Buses/BusPaymentSuccess/BusPaymentSuccess";
import BusTicket from "./../../admin/Buses/BusTicketScreen";
import ApiConfigue from "./../../admin/ApiConfigue/ApiConfigue";
import BookingReports from "./../../admin/BookingReports/BookingReports";
import ApiReference from "./../../admin/ApiReference/ApiReference";
import BankDetails from "./../../admin/BankDetails/BankDetails";
import ChangePassword from "./../../admin/ChangePassword/ChangePassword";
import { useAuthContext } from "../../common/providers/AuthProvider";
import Query from "../../admin/Query/Query";
import DetailedQuery from "../../admin/DetailedQuery/DetailedQuery";
import AdminBusCommissions from "../../admin/BusCommissions/AdminBusCommissions";
import MissingRoutes from "../../admin/MissingRoutes/MissingRoutes";
import Busavailable from "../../admin/Buses/BusResults/Busavailable";
import TopBusOperators from "../../admin/TopBusOperators/TopBusOperators";
import TopBusRoutes from "../../admin/TopBusRoutes/TopRoutes";
import ConsolidateLedger from "../../admin/ConsolidateLedger/ConsolidateLedger";
import ConsolidateStatements from "../../admin/ConsolidateStatements/ConsolidateStatements";
import UserIPAddressConfig from "../../admin/UserIPAddressConfig/UserIPAddressConfig";
import ErrorCodes from "../../admin/ErrorCodes/ErrorCode";
import PgReturnUrl from "../../helpers/PgReturnUrl";
import PgSuccess from "../../helpers/PgSuccess";

const AdminNavigations = ({ accessList, accessToken }) => {
  const { user } = useAuthContext();

  return (
    <div className="mob-M-Top">
      <Switch>
        {/* {user?.roleId === 2 ? ( */}
        <Route exact path="/" component={Charts} />
        {/* ) : (
          <Route exact path="/" component={Dashboard} />
        )} */}

        {/* <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/dashboard1"
          component={Dashboard1}
        /> */}
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/dashboard"
          component={Dashboard}
        />

        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/role"
          component={Role}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/membership"
          component={Membership}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/apireference"
          component={ApiReference}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/siteadmin"
          component={Siteadmin}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/siteadmins-deposit"
          component={SiteadminDeposit}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/siteadmins-statement"
          component={SiteadminStatement}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/siteadmins-quickhelp"
          component={SiteadminQuickHelp}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/apiConfigue"
          component={ApiConfigue}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/category"
          component={Category}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/bookingreports"
          component={BookingReports}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/missingroutes"
          component={MissingRoutes}
        />

        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/screens"
          component={Screens}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/screenmapping"
          component={ScreenMapping}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/buscommissions"
          component={BusCommissions}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/adminbuscommissions"
          component={AdminBusCommissions}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/operators"
          component={Operators}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/suppliers"
          component={Suppliers}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/busbooking"
          component={BusBooking}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/busresults"
          component={BusResults}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/busresultsavailable"
          component={Busavailable}
        />





        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/bus-checkout"
          component={BusCheckout}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/bus-review"
          component={BusReview}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/bus-success"
          component={BusSuccess}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/bus_ticket"
          component={BusTicket}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/bankdetails"
          component={BankDetails}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/changepassword"
          component={ChangePassword}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/user"
          component={AdminUser}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/statements"
          component={Statements}
        />

        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/accesspermissions"
          component={AccessPermissions}
        />

        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/provider"
          component={AddProvider}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/apiprovider"
          component={ProviderApi}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/reports"
          component={Reports}
        />

        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/promo"
          component={Promocode}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/subscriberreport"
          component={SubscriberReport}
        />

        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/banners"
          component={Banners}
        />

        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/travelCalendar"
          component={TravelCalendar}
        />

        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/busoperator"
          component={BusOperatorsService}
        />

        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/flightoperator"
          component={FlightOperatorsService}
        />

        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/busmarkup"
          component={BusesCommissions}
        />

        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/flightmarkup"
          component={FlightsMarkup}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/Siteusers"
          component={Siteusers}
        />

        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/flightticket"
          render={() => <FlightTicket />}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/CMS"
          component={CMS}
        />

        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/apilogs"
          component={APILogs}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/pglogs"
          component={PGLogs}
        />

        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/dblogs"
          component={DBLogs}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/settings"
          component={Settings}
        />

        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/createlocation"
          component={Createlocation}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/updatelocation"
          component={Updatelocation}
        />

        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/admin"
          component={Admin}
        />

        <Route
          exact
          path="/admin/super_flight_ticket"
          component={SuperFlightTicket}
        />
        <Route
          exact
          path="/admin/super_bus_ticket"
          component={SuperBusTicket}
        />

        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/contactforms"
          component={ContactForm}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/flightenquiries"
          component={FlightEnquires}
        />

        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/query"
          component={Query}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/detailedquery"
          component={DetailedQuery}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/topbusoperators"
          component={TopBusOperators}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/toproutes"
          component={TopBusRoutes}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/ConsolidateStatements"
          component={ConsolidateStatements}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/site-admin/ConsolidateLedger"
          component={ConsolidateLedger}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/useripaddressconfig"
          component={UserIPAddressConfig}
        />
          <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/errorcodes"
          component={ErrorCodes}
        />
        


        <Route exact path="/admin/cancelrequest" component={CancelRequest} />

        <Route exact path="/unauthorized" component={Unauthorized} />
        <Route exact path="/404" component={Error404} />
        <Route exact path={"/PgReturn"} component={PgReturnUrl} />
        <Route exact path={"/pgsuccess"} component={PgSuccess} />
        <Route component={NotFound} />
      </Switch>
    </div>
  );
};

export default AdminNavigations;
